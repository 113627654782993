import { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ResetPasswordSecondPageProps, ResetPasswordSecondPageFormData } from './types';
import emailIcon from '@assets/images/email.svg';
import { useAuthentication } from '@store/index';
import styles from './resetPasswordSecondPage.module.scss';
import { ROUTES, ExpertPage } from '@routes/constants';
import { Typography, Grid } from '@mui/material';

type SetActivePageType = (page: ExpertPage) => void;

export const useSubmit = (
    setActivePage: SetActivePageType,
    setGlobalAlertMessage: ResetPasswordSecondPageProps['setGlobalAlertMessage'],
    setShouldShowGlobalAlert: ResetPasswordSecondPageProps['setShouldShowGlobalAlert'],
    code: string,
) => {
    return useCallback(
        ({ password }: ResetPasswordSecondPageFormData) => {
            return useAuthentication
                .getState()
                .confirmChangePassword(code, password)
                .then(() => {
                    setGlobalAlertMessage({
                        text: (
                            <div className={styles.alertWrapper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <img src={emailIcon} alt="email logo" />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" className={styles.successAlertBody}>
                                            New password saved. Please{' '}
                                            <Link to={ROUTES.EXPERT.PATHS.AUTH_LOGIN} className={styles.supportPageLink}>
                                                log in
                                            </Link>{' '}
                                            .
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        ),
                        type: 'info',
                        closable: true,
                        showIcon: false,
                    });
                    setActivePage('AUTH_LOGIN');
                })
                .catch(() => {
                    setGlobalAlertMessage({
                        type: 'error',
                        text: 'Your request could not be submitted. Click "Save" to try again.',
                        closable: true,
                    });
                })
                .finally(() => {
                    setShouldShowGlobalAlert(true);
                });
        },
        [code, setActivePage, setGlobalAlertMessage, setShouldShowGlobalAlert],
    );
};

export const useUnmount = (
    setShouldShowGlobalAlert: ResetPasswordSecondPageProps['setShouldShowGlobalAlert'],
) => {
    useEffect(() => {
        return () => {
            setShouldShowGlobalAlert(false);
        };
    }, [setShouldShowGlobalAlert]);
};