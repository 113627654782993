import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useForm} from 'react-hook-form';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useActivePage, useIsMobile } from '@hooks/index';
import {
    PageContainer,
    RouteLeavingGuard,
    PersonalDetailsForm,
    CompanyDetailsForm,
    JobDetailsForm
} from '@components/index';
import { getInitialFormValues } from './constants';
import {
    useOnSubmit,
    useLocationOptions,
    useOnMount,
    useOnUnmount,
} from './hooks';

import { FROM } from '@shared/constants';
import { EditSettingsFormData } from './types';
import styles from './profile.module.scss';
import { GlobalAlertMessage } from '@models/GlobalAlert.ts';
import { useExpert } from '@store/index';
import {useLocation} from "react-router-dom";

export const Profile = () => {
    const from = new URLSearchParams(useLocation().search).get('from');
    const [shouldShowGlobalAlert, setShouldShowGlobalAlert] = useState(false);
    const [globalAlert, setGlobalAlertMessage] = useState<GlobalAlertMessage | null>(null);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [selectedCountry, setSelectedCountry] = useState<string>("");
    const isMobile = useIsMobile();
    const [currentExpert, setCurrentExpert] = useState(useExpert.getState().currentExpert);
    const patchCurrentExpertStatus = useExpert.getState().patchCurrentExpertStatus;
    const [loading, setLoading] = useState(true);
    const initialFormValues = currentExpert ? getInitialFormValues(currentExpert) : undefined;
    const locationOptions = useLocationOptions();
    const onSubmit = useOnSubmit(setGlobalAlertMessage, setShouldShowGlobalAlert, setIsSaveDisabled);
    const [, setActivePage] = useActivePage();
    useOnMount(setShouldShowGlobalAlert, setIsSaveDisabled);
    useOnUnmount(setShouldShowGlobalAlert, setIsSaveDisabled);

    const { handleSubmit, control, reset, watch, setValue } = useForm<EditSettingsFormData>({
        defaultValues: initialFormValues,
    });

    const watchedFields = watch();

    useEffect(() => {
        const getExpert = async () => {
            const expert = await useExpert.getState().getCurrentExpert();
            if (!expert.first_name) {
                if (from === FROM.VERIFICATION) {
                    setActivePage('AUTH_COMPLETE_PROFILE', {'from': from});
                } else {
                    setActivePage('AUTH_COMPLETE_PROFILE');
                }
            }
            setCurrentExpert(expert);
            setSelectedCountry(expert.country ?? '');
            reset(getInitialFormValues(expert));
            setLoading(false);
        };
        getExpert();
    }, [setActivePage, from, reset]);

    useEffect(() => {
        const initialValues = getInitialFormValues(currentExpert);
        const isFormChanged = (Object.keys(initialValues) as (keyof EditSettingsFormData)[])
            .some(key => watchedFields[key] !== initialValues[key]);
        setIsSaveDisabled(!isFormChanged);
    }, [watchedFields, currentExpert]);

    const handleCancel = () => {
        reset(getInitialFormValues(currentExpert));
        setSelectedCountry(currentExpert.country ?? '');
        setIsSaveDisabled(true);
    };

    const isLoading = patchCurrentExpertStatus === 'started';

    return (
        <>
            <PageContainer
                shouldShowGlobalAlert={shouldShowGlobalAlert}
                globalAlertMessage={globalAlert}
                setShouldShowGlobalAlert={setShouldShowGlobalAlert}
                hasLeftSection={true}
                selectedMenuItem={"myProfile"}
            >
                <Typography variant='h1'>
                    Profile settings
                </Typography>
                {loading && <CircularProgress size={40} />}
                {!loading && <div
                    className={classNames({
                        [styles.editSettings]: true,
                        [styles['is-mobile']]: isMobile,
                    })}
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <PersonalDetailsForm control={control} showTitles={true} setSelectedCountry={setSelectedCountry} setValue={setValue} selectedCountry={selectedCountry} locationOptions={locationOptions} />
                        <CompanyDetailsForm control={control} showTitles={true} />
                        <JobDetailsForm control={control} showTitles={true} />
                        <Box mt={2} sx={{ display: 'flex', flexDirection: 'row', gap: '16px', width: '100%', justifyContent: 'end', mb: '30px' }}>
                            <Button
                                variant="text"
                                color="secondary"
                                onClick={handleCancel}
                            >
                                CANCEL
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                disabled={isSaveDisabled || isLoading}
                            >
                                SAVE CHANGES
                            </Button>
                        </Box>
                    </form>
                    <RouteLeavingGuard when={!isSaveDisabled} />
                </div>}
            </PageContainer>
        </>
    );
};

export default Profile;