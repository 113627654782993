import { MouseEventHandler, useEffect, useState } from 'react';
import classNames from 'classnames';
import {useActivePage, useIsMobile} from '@hooks/index';
import { Dialog, DialogTitle, DialogContent, Typography, Box, Button as MuiButton, Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import {PageContainer, NewPasswordInput, TermsAndConditions, Input} from '@components/index';
import { useOnSubmit, useOnMount, useOnUnmount } from './hooks.tsx';
import { GlobalAlertMessage } from '@models/GlobalAlert';
import { RegistrationPageFormData } from '@models/Registration.ts';
import styles from './signUp.module.scss';

export const SignUp = () => {
    const [shouldShowGlobalAlert, setShouldShowGlobalAlert] = useState(false);
    const [globalAlertMessage, setGlobalAlertMessage] = useState<GlobalAlertMessage | null>(null);
    const isMobile = useIsMobile();
    const [, setActivePage] = useActivePage();
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
    const [isTermsShown, setIsTermsShown] = useState<boolean>(false);
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState(false);
    const { control, handleSubmit, watch, formState: { errors } } = useForm<RegistrationPageFormData>();
    const handleTermsAndConditionClick: MouseEventHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsTermsShown(true);
    };

    const handleLoginClicked = () => {
        setActivePage('AUTH_LOGIN')
    }

    const handleFormSubmit = useOnSubmit(setGlobalAlertMessage, setShouldShowGlobalAlert, setIsLoading);

    useOnMount(setShouldShowGlobalAlert);
    useOnUnmount(setShouldShowGlobalAlert);

    const watchedFields = watch();

    useEffect(() => {
        const {
            password,
            email,
            acceptedTerms
        } = watchedFields;

        const isDisabled = !email || !password || !acceptedTerms || !isPasswordValid || isLoading;
        setButtonDisabled(isDisabled);
    }, [watch, isPasswordValid, watchedFields, isLoading]);

    return (
        <PageContainer globalAlertMessage={globalAlertMessage}
                       shouldShowGlobalAlert={shouldShowGlobalAlert}
                       setShouldShowGlobalAlert={setShouldShowGlobalAlert}
                       isModal={true}
        >
            <div
                className={classNames({
                    [styles.registration]: true,
                    [styles['is-mobile']]: isMobile,
                })}
            >
                <Dialog
                    open={isTermsShown}
                    onClose={() => setIsTermsShown(false)}
                    fullWidth
                    maxWidth={isMobile ? 'xs' : 'md'}
                    PaperProps={isMobile ? { sx: { top: '8px' } } : {}}
                >
                    <DialogTitle>
                        <Typography variant='h1' sx={{ textAlign: 'center' }}>
                            Terms and conditions
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <TermsAndConditions />
                    </DialogContent>
                </Dialog>

                <Typography variant='h1' fontWeight="bold" textAlign="left" mb={'30px'}>
                    Create your account
                </Typography>

                <form onSubmit={handleSubmit(handleFormSubmit)} className={styles.form} noValidate>
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: 'Please input a valid email',
                            pattern: {
                                value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                message: 'Please input a valid email',
                            },
                        }}
                        render={({ field }) => (
                            <Input
                                {...field}
                                label="Enter email address"
                                variant="outlined"
                                fullWidth
                                error={!!errors.email}
                                helperText={errors.email ? errors.email.message : 'We’ll use this email to pay you and reach out about new survey and consultation opportunities.'}
                                sx={{ mb: 2 }}
                            />
                        )}
                    />

                    <Controller
                        name="password"
                        control={control}
                        rules={{
                            required: 'Please input your password',
                        }}
                        render={({ field }) => (
                            <NewPasswordInput
                                {...field}
                                label="Create password"
                                onValidationChange={setIsPasswordValid}
                                showValidation={true}
                            />
                        )}
                    />

                    <Controller
                        name="acceptedTerms"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <MuiCheckbox
                                        {...field}
                                    />
                                }
                                label={
                                    <Box>
                                        <Typography variant="body2">
                                            I accept the{' '}
                                            <span
                                                className={styles.termsAndConditionsLink}
                                                onClick={handleTermsAndConditionClick}
                                            >
                                                Terms and Conditions
                                            </span>
                                            .*
                                        </Typography>
                                    </Box>
                                }
                                sx={{ mb: 2 }}
                            />
                        )}
                    />

                    <MuiButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={buttonDisabled}
                        fullWidth
                        sx={{ mb: 2 }}
                    >
                        CREATE ACCOUNT
                    </MuiButton>
                    <MuiButton
                        type="button"
                        variant="text"
                        color="primary"
                        fullWidth
                        onClick={handleLoginClicked}
                    >
                        GO BACK TO LOG IN
                    </MuiButton>
                </form>
            </div>
        </PageContainer>
    );
};

export default SignUp;