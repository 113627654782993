import { useCallback, useEffect, useMemo } from 'react';
import { Record } from 'immutable';
import { throttle } from 'lodash';
import { CompleteProfileForm2Props } from './types';
import { CompleteProfileForm2Data} from "@pages/Registration/CompleteProfile/types.ts";
import useIndustries from "@store/useIndustries/useIndustries.ts";
import {countryList} from "@shared/countries.ts";
import {statesList} from "@shared/states.ts";
import {cityStateLookup} from "@services/experts/experts.service.ts";

export const useHandleSubmit = (setCompleteProfileForm2Data: (data: CompleteProfileForm2Data) => void) => {

  return useCallback((data: CompleteProfileForm2Data) => {
    setCompleteProfileForm2Data(Record(data)());
  }, [setCompleteProfileForm2Data]);
};

export const getLocations = (search: string) => {
  const fullList = [...countryList];
  return fullList.filter((item) => {item.toLowerCase().includes(search.toLowerCase())});
}

export const resetLocations = () => {
  return [...countryList]
}

export const useOnSearchLocations = () => {
  return useCallback(
    (searchTerm: string) => {
      if (searchTerm) {
        getLocations(searchTerm);
      } else {
        resetLocations();
      }
    },
    [],
  );
};

export const useLocationOptions = () => {

  return useMemo(() => {
    const locations : string[] = [...countryList];
    return locations.map((location) => {
      return (
          location
      );
    });
  }, []);
};

export const useStateOptions = () => {

  return useMemo(() => {
    const states : string[] = [...statesList];
    return states.map((location) => {
      return (
         location
      );
    });
  }, []);
};

export const useOnMount = (
    setGlobalAlertMessage: CompleteProfileForm2Props['setGlobalAlertMessage'],
    setShouldShowGlobalAlert: CompleteProfileForm2Props['setShouldShowGlobalAlert'],
    setLoading: (loading: boolean) => void
) => {
  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        await useIndustries.getState().getIndustries();
      } catch {
        setGlobalAlertMessage({
          text: 'An error occurred when fetching industries. Refresh the page to try again.',
          type: 'error',
        });
        setShouldShowGlobalAlert(true);
      } finally {
        setLoading(false);
      }
    };
    fetchIndustries();
  }, [setGlobalAlertMessage, setShouldShowGlobalAlert, setLoading]);
};

export const useOnUnmount = (
  setShouldShowGlobalAlert: CompleteProfileForm2Props['setShouldShowGlobalAlert'],
) => {
  return useEffect(() => {
    return () => {
      setShouldShowGlobalAlert(false);
    };
  }, [setShouldShowGlobalAlert]);
};

export const useIndustryOptions = () => {

  const industries = useIndustries.getState().industries;
  return useMemo(() => {
    return industries.map((industry) => {
      const { display_name } = industry;

      return (
        display_name
      );
    });
  }, [industries]);
};

const THROTTLE_DELAY = 800; // Delay in ms
export const validateZipcode = throttle((zipcode: string) => {
  return cityStateLookup({"zipcode": zipcode})
}, THROTTLE_DELAY);