import { useCallback, useEffect } from 'react';
import { Record } from 'immutable';

import {CompleteProfileForm1Data} from "@pages/Registration/CompleteProfile/types.ts";

import { CompleteProfileForm1Props } from './types';

export const useOnUnmount = (
  setShouldShowGlobalAlert: CompleteProfileForm1Props['setShouldShowGlobalAlert'],
) => {
  useEffect(() => {
    return () => {
      setShouldShowGlobalAlert(false);
    };
  }, [setShouldShowGlobalAlert]);
};

export const useHandleSubmit = (
  setShouldShowGlobalAlert: CompleteProfileForm1Props['setShouldShowGlobalAlert'],
  setGlobalAlertMessage: CompleteProfileForm1Props['setGlobalAlertMessage'],
  setCompleteProfileForm1Data : (data: CompleteProfileForm1Data) => void,
  setShowNextForm : (value : boolean) => void,
) => {

  return useCallback(
    (data: CompleteProfileForm1Data) => {
      const { professional_email_address, linkedin_profile_url, hasNoLinkedinURLOrEmail } = data;
        if (professional_email_address) {
            localStorage.setItem('current_professional_email', professional_email_address);
        }
      if (!professional_email_address && !linkedin_profile_url && !hasNoLinkedinURLOrEmail) {
        setGlobalAlertMessage({
          text: 'To continue, please provide a professional email, your LinkedIn URL, or select "I do not have a professional email or LinkedIn account."',
          type: 'info',
        });

        setShouldShowGlobalAlert(true);
      } else {
        setShouldShowGlobalAlert(false);
        setCompleteProfileForm1Data(Record(data)());
        setShowNextForm(true);
      }
    },
    [setShowNextForm, setGlobalAlertMessage, setShouldShowGlobalAlert, setCompleteProfileForm1Data],
  );
};