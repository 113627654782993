import {useEffect, useState} from 'react';
import classNames from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { Typography, Box, Button as MuiButton } from '@mui/material';
import { PageContainer, Input, NewPasswordInput } from '@components/index';
import { useActivePage, useIsMobile } from '@hooks/index';
import { useAuthentication } from '@store/index';
import { useLocation } from 'react-router-dom';
import { GlobalAlertMessage } from '@models/GlobalAlert.ts';
import { FROM } from '@shared/constants';
import { FormData } from './types';
import styles from './login.module.scss';
import CustomAlert from "@components/atoms/Alert";

export const Login = () => {
    const from = new URLSearchParams(useLocation().search).get('from');
    const isMobile = useIsMobile();
    const [shouldShowGlobalAlert, setShouldShowGlobalAlert] = useState(false);
    const [globalAlertMessage, setGlobalAlertMessage] = useState<GlobalAlertMessage | null>(null);
    const { control, handleSubmit, formState: { errors } } = useForm<FormData>();
    const [, setActivePage] = useActivePage();
    const [isDisabled] = useState(false);
    const [errorLogin, setErrorLogin] = useState(false);

    const onSubmit = (formData: FormData) => signIn(formData);
    const handleForgotPassword = () => setActivePage('AUTH_FORGOT_PASSWORD');
    const handleRegister = () => setActivePage('AUTH_SIGN_UP');

    const title = 'Welcome';
    useEffect(() => {
        if (from) {
            setGlobalAlertMessage({
                text : <CustomAlert title="Account activated" message="Log in to finish signing up." type='success'  />,
                type : 'success',
            })
            setShouldShowGlobalAlert(true);
        }
    }, [from, title]);
    const signIn = async (formData: FormData) => {
        const result = await useAuthentication.getState().login(formData.email, formData.password);
        if (result) {
            setErrorLogin(false);
            if (from === FROM.VERIFICATION) {
                setActivePage('ACCOUNT_PROFILE', {'from': from});
            } else {
                setActivePage('ACCOUNT_PROFILE');
            }
        } else {
            setErrorLogin(true);
        }
    };

    return (
        <PageContainer setShouldShowGlobalAlert={setShouldShowGlobalAlert} isModal={true} globalAlertMessage={globalAlertMessage} shouldShowGlobalAlert={shouldShowGlobalAlert}>
            <div
                className={classNames({
                    [styles.login]: true,
                    [styles['is-mobile']]: isMobile,
                })}
            >
                <Typography variant="h1" fontWeight="bold" textAlign="center" mb={'44px'}>
                    {title}
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: 'Email is required.',
                            pattern: {
                                value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                message: 'Please enter a valid email address.'
                            }
                        }}
                        render={({ field }) => (
                            <Input
                                {...field}
                                label="Email"
                                variant="outlined"
                                fullWidth
                                error={!!errors.email}
                                helperText={errors.email ? errors.email.message : ''}
                                sx={{ mb: 2 }}
                            />
                        )}
                    />

                    <Box className={styles.passwordContainer}>
                        <Controller
                            name="password"
                            control={control}
                            rules={{ required: 'Please input your password' }}
                            render={({ field }) => (
                                <NewPasswordInput
                                    {...field}
                                    label="Password"
                                />
                            )}
                        />
                        <Typography
                            variant='caption'
                            className={styles.forgotPassword}
                            onClick={handleForgotPassword}
                        >
                            Forgot password?
                        </Typography>
                    </Box>

                    {errorLogin && (
                        <Typography variant="body2" className={styles.incorrectCredential}>
                            The email or password you entered is incorrect.
                        </Typography>
                    )}

                    <Box className={styles.loginButtonContainer}>
                        <MuiButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isDisabled}
                            className={styles.submit}
                            fullWidth
                            sx={{ mb: 2 }}
                        >
                            LOG IN
                        </MuiButton>
                        <MuiButton
                            variant="text"
                            color="primary"
                            onClick={handleRegister}
                            fullWidth
                        >
                            CREATE AN ACCOUNT
                        </MuiButton>
                    </Box>
                </form>
            </div>
        </PageContainer>
    );
};

export default Login;