import { FC } from 'react';
import { useActivePage } from '@hooks/index';
import {List, ListItemButton, ListItemText, Box, Typography, ListItemIcon} from '@mui/material';
import { ExpertPage } from '@routes/constants';

import { Props } from './types';
import svgSrc2 from '@assets/images/selectedMenuItem.svg';

export const ProfileMenu: FC<Props> = (props) => {
    const { selectedMenuItem, className = '' } = props;

    const [, setActivePage] = useActivePage();

    const menuItems: { name: string; help: string; page: ExpertPage; selected: boolean }[] = [
        {
            name: "Profile settings",
            help: "Manage personal information and how we can reach you.",
            page: 'ACCOUNT_PROFILE',
            selected: selectedMenuItem === 'myProfile'
        },
        {
            name: "Login & security",
            help: "Make sure the knowledge you can share is fresh and up to date.",
            page: 'ACCOUNT_SECURITY',
            selected: selectedMenuItem === 'loginAndSecurity'
        }
    ];

    return (
        <Box className={className} sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <List>
                {menuItems.map((item) => (
                    <ListItemButton
                        key={item.name}
                        selected={item.selected}
                        onClick={() => setActivePage(item.page)}
                        sx={{ paddingLeft: 2,  backgroundColor: 'transparent',
                            '&.Mui-selected': {
                                backgroundColor: 'transparent',
                            },
                            '&.Mui-selected:hover': {
                                backgroundColor: 'transparent',
                            },
                            '&:hover': {
                                backgroundColor: 'transparent',
                            }, }}
                    >
                        {item.selected && (
                            <ListItemIcon sx={{position: 'absolute', left: '-16px'}}>
                                <img alt="arrow" src={svgSrc2}/>
                            </ListItemIcon>
                        )}
                        <ListItemText
                            primary={<Typography variant="h6" sx={{color : item.selected ? '#0F4BFF' : '', fontWeight : 'bold'}}>{item.name}</Typography>}
                        />
                    </ListItemButton>
                ))}
            </List>
        </Box>
    );
};

export default ProfileMenu;