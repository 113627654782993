import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageContainer } from '@components/index';
import classNames from 'classnames';
import styles from './userEmailVerified.module.scss';
import { useIsMobile } from '@hooks/index';
import { ROUTES } from '@routes/constants';
import { useAuthentication } from '@store/index';
import { CircularProgress, Button, Typography } from '@mui/material';

const VerifySuccess: React.FC = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const isMobile = useIsMobile();

    useEffect(() => {
        const handleVerifyEmail = async (code: string, continueUrl: string | null) => {
            try {
                await useAuthentication.getState().verifyEmail(code);
                setSuccessMessage('Your email has been successfully verified.');
                setIsLoading(false);
                if (continueUrl) {
                    navigate(`${ROUTES.EXPERT.PATHS.AUTH_LOGIN}?from=verification`);
                }
            } catch (err) {
                // If error, display it
                console.log(err);
                setError('The user is already verified');
                setIsLoading(false);
                if (continueUrl) {
                    navigate(`${ROUTES.EXPERT.PATHS.AUTH_LOGIN}?from=verification`);
                }
            }
        };
        setIsLoading(true);
        const urlParams = new URLSearchParams(window.location.search);
        const mode = urlParams.get('mode');
        const actionCode = urlParams.get('oobCode') ?? '';
        const continueUrl = urlParams.get('continueUrl');
        switch (mode) {
            case 'verifyEmail':
                handleVerifyEmail(actionCode, continueUrl);
                break;
            default:
                break;
        }
    }, [navigate]);

    const handleBackToLogin = () => {
        navigate(`${ROUTES.EXPERT.PATHS.AUTH_LOGIN}?from=verification`);
    };

    if (isLoading) {
        return <CircularProgress size={60} />;
    }

    return (
        <>
            <PageContainer>
                <div
                    className={classNames({
                        [styles.registrationSuccess]: true,
                        [styles['is-mobile']]: isMobile,
                    })}
                >
                    <Typography variant="h1">
                        {successMessage ? 'Email Verified' : 'Email is already verified'}
                        <br />
                    </Typography>

                    {successMessage && (
                        <Typography className={styles.para1} variant='body1'>
                            {successMessage}
                        </Typography>
                    )}
                    {error && !successMessage && (
                        <Typography className={styles.para1} variant='body1'>
                            {error}
                        </Typography>
                    )}
                    <div className={styles.loginButtonContainer}>
                        <Button
                            className={styles.submit}
                            onClick={handleBackToLogin}
                            variant="contained"
                            color="primary"
                        >
                            <span className={styles.loginText}>GO BACK TO LOG IN</span>
                        </Button>
                    </div>
                </div>
            </PageContainer>
        </>
    );
};

export default VerifySuccess;