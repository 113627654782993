import {useEffect, useState} from 'react';
import {NewPasswordInput,
    PageContainer,RouteLeavingGuard,
} from "@components/index.ts";
import { LoginAndSecurityFormData} from "./types";
import styles from "./loginAndSecurity.module.scss";
import {GlobalAlertMessage} from "@models/GlobalAlert.ts";
import {useIsMobile} from "@hooks/index.ts";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useOnCancel, useOnMount, useOnSubmit, useOnUnmount } from "./hooks.tsx";
import { useExpert } from "@store/index";
import classNames from "classnames";
import {Box, Button, TextField, Typography} from "@mui/material";

const Security = () => {
    const [ shouldShowGlobalAlert, setShouldShowGlobalAlert] = useState(false);
    const [ globalAlertMessage, setGlobalAlertMessage] = useState<GlobalAlertMessage | null>(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
    const [loading, setIsLoading] = useState(false);
    const isMobile = useIsMobile();
    const methods = useForm<LoginAndSecurityFormData>();
    const { watch, setValue, handleSubmit, control } = methods;

    const { currentExpert } = useExpert.getState();

    const initialValues: LoginAndSecurityFormData = {
        email_address: currentExpert?.email_address ?? '',
        current_password: '',
        new_password: '',
    };

    const onSubmit = useOnSubmit(setIsLoading, setGlobalAlertMessage, setShouldShowGlobalAlert);
    const onCancel = useOnCancel(setValue, initialValues, setShouldShowGlobalAlert);
    const { current_password, new_password } = watch();
    useEffect(() => {
        const isDisabled = !current_password || !new_password || !isPasswordValid;
        setIsButtonDisabled(isDisabled);
    }, [current_password, new_password, isPasswordValid]);

    useOnMount(setShouldShowGlobalAlert);
    useOnUnmount(setShouldShowGlobalAlert);

    return (
        <>
            <FormProvider {...methods}>
                <PageContainer
                    setShouldShowGlobalAlert={setShouldShowGlobalAlert}
                    globalAlertMessage={globalAlertMessage}
                    shouldShowGlobalAlert={shouldShowGlobalAlert}
                    hasLeftSection={true}
                    selectedMenuItem={"loginAndSecurity"}
                >
                    <Typography variant='h1'>
                        Login & security
                    </Typography>
                    <div
                        className={classNames(styles.container, {
                            [styles['is-mobile']]: isMobile,
                        })}
                    >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div style={{ display: "none"}}>
                                <Typography variant='h4' className={styles.emailSubTitle}>
                                    Change login email
                                </Typography>

                                <Typography variant='body2' className={styles.message}>
                                    Your login email is what we’ll use to contact you about paid opportunities and send you
                                    payment after you complete a consultation.
                                </Typography>

                                <Controller
                                    name="email_address"
                                    control={control}
                                    defaultValue={initialValues.email_address}
                                    rules={{
                                        required: 'Please input your email address',
                                        pattern: {
                                            value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                            message: 'Log in email not correct. Try again.',
                                        },
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            label='Login email'
                                            fullWidth
                                            data-testid="Email Address"
                                            onChange={(e) => {
                                                field.onChange(e);
                                            }}
                                            error={!!error}
                                            helperText={error ? error.message : ''}
                                        />
                                    )}
                                />
                            </div>

                            <Typography variant='h4' className={styles.passwordSubTitle}>
                                Change password
                            </Typography>

                            <Controller
                                name="current_password"
                                control={control}
                                defaultValue=""
                                rules={{ required: 'Please input your current password' }}
                                render={({ field }) => (
                                    <NewPasswordInput
                                        {...field}
                                        label="Current password"
                                        className={styles.currentPassword}
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    />
                                )}
                            />

                            <Controller
                                name="new_password"
                                control={control}
                                defaultValue=""
                                rules={{ required: 'Please input your new password' }}
                                render={({ field }) => (
                                    <NewPasswordInput
                                        {...field}
                                        showValidation={true}
                                        onValidationChange={setIsPasswordValid}
                                        label='New password'
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    />
                                )}
                            />

                            <Box mt={2} sx={{display: 'flex', flexDirection: 'row', gap: '16px', width: '100%', justifyContent: 'end'}}>
                                <Button
                                    variant="text"
                                    color="secondary"
                                    onClick={onCancel}
                                >
                                    CANCEL
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    disabled={isButtonDisabled || loading}
                                >
                                    UPDATE PASSWORD
                                </Button>
                            </Box>
                        </form>
                        <RouteLeavingGuard when={!isButtonDisabled} />
                    </div>
                </PageContainer>
            </FormProvider>
        </>
    );
};

export default Security;