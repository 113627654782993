import { memo, useEffect, useState } from 'react';
import {NewPassword, UserEmailVerified } from "./components/index";
import {GlobalAlertMessage} from "@models/GlobalAlert.ts";

const CustomEmailAction: React.FC = memo(() => {
    const [, setShouldShowGlobalAlert] = useState(false);
    const [, setGlobalAlertMessage] = useState<GlobalAlertMessage | null>(null);
    const [mode, setMode] = useState('');
    const [code, setCode] = useState('');

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const mode = urlParams.get('mode');
        const actionCode = urlParams.get('oobCode');
        setMode(mode ?? '');
        setCode(actionCode ?? '');
    }, []);

    return (
        <>
            {mode === 'verifyEmail' && <UserEmailVerified />}
            {mode === 'resetPassword' && (
                <NewPassword code={code} setShouldShowGlobalAlert={setShouldShowGlobalAlert} setGlobalAlertMessage={setGlobalAlertMessage}
                />
            )}
        </>
    );
});

export default CustomEmailAction;
