export type StateList = Array<
    | 'Alabama'
    | 'Alaska'
    | 'Arizona'
    | 'Arkansas'
    | 'California'
    | 'Colorado'
    | 'Connecticut'
    | 'Delaware'
    | 'Florida'
    | 'Georgia'
    | 'Hawaii'
    | 'Idaho'
    | 'Illinois'
    | 'Indiana'
    | 'Iowa'
    | 'Kansas'
    | 'Kentucky'
    | 'Louisiana'
    | 'Maine'
    | 'Maryland'
    | 'Massachusetts'
    | 'Michigan'
    | 'Minnesota'
    | 'Mississippi'
    | 'Missouri'
    | 'Montana'
    | 'Nebraska'
    | 'Nevada'
    | 'New Hampshire'
    | 'New Jersey'
    | 'New Mexico'
    | 'New York'
    | 'North Carolina'
    | 'North Dakota'
    | 'Ohio'
    | 'Oklahoma'
    | 'Oregon'
    | 'Pennsylvania'
    | 'Rhode Island'
    | 'South Carolina'
    | 'South Dakota'
    | 'Tennessee'
    | 'Texas'
    | 'Utah'
    | 'Vermont'
    | 'Virginia'
    | 'Washington'
    | 'West Virginia'
    | 'Wisconsin'
    | 'Wyoming'
    | 'District of Columbia'
    | 'Puerto Rico'
    | 'Guam'
    | 'American Samoa'
    | 'U.S. Virgin Islands'
    | 'Northern Mariana Islands'
>;

export const statesList: StateList = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
    'District of Columbia',
    'Puerto Rico',
    'Guam',
    'American Samoa',
    'U.S. Virgin Islands',
    'Northern Mariana Islands',
];

export const statesMap: Record<string, string> = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District of Columbia",
    "FL": "Florida",
    "GA": "Georgia",
    "GU": "Guam",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VI": "U.S. Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
}
