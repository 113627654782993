import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthentication } from '@store/index.ts';
import { ROUTES } from '../constants';

interface AuthGuardProps {
    children: ReactNode;
}

const LandingPageGuard = ({ children }: AuthGuardProps) => {
    const isAuthenticated = useAuthentication((state) => state.isAuthenticated);

    if (isAuthenticated) {
        // Redirect to the login page if the user is not authenticated
        return <Navigate to={ROUTES.EXPERT.PATHS.ACCOUNT_PROFILE} replace />;
    }

    // Render the children if the user is authenticated
    return <>{children}</>;
};

export default LandingPageGuard;