import { create } from 'zustand';
import {getIndustries} from "@services/industries/industries.service.ts";
import {IndustryResult} from "@services/industries/adapters.ts";

export type IndustriesState = {
    industries: IndustryResult[];
    loading: boolean;
    getIndustries: () => Promise<IndustryResult[]>;
};

const useIndustries = create<IndustriesState>((set,) => ({
    industries: [],
    loading: false,
    getIndustries: async () => {
        set(() => ({ loading: true }));
        const industries = await getIndustries();
        set(() => ({ industries: industries }));
        return industries;
    },
}));

export default useIndustries;
