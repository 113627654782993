import { create } from 'zustand';
import {
    activateExpert,
    getCurrentExpert,
    patchCurrentExpert,
    sendExpertVerificationCode, verifyExpert
} from '@services/experts/experts.service';
import {Expert} from "@models/Expert.ts";
import {
    ActivateExpertBody,
    GetCurrentExpertResponse,
    PatchCurrentExpertBody,
    PatchCurrentExpertResponse, VerifyExpertBody
} from "@services/experts/adapters.ts";

export type ExpertState = {
    currentExpert: Expert | PatchCurrentExpertBody;
    patchCurrentExpertStatus: string;
    getCurrentExpert: () => Promise<GetCurrentExpertResponse>;
    patchCurrentExpert: (expert: Expert | PatchCurrentExpertBody) => Promise<PatchCurrentExpertResponse>;
    sendExpertVerificationCode: (email: string) => Promise<void>;
    activateExpert: (data: ActivateExpertBody) => Promise<void>;
    verifyExpert: (data: VerifyExpertBody) => Promise<void>;
};

const useExpert = create<ExpertState>((set,) => ({
    currentExpert: {
        availability: undefined,
        auth0_user_id: '',
        company_name: undefined,
        company_size: undefined,
        company_website: undefined,
        created_at: '',
        did_agree_to_terms: false,
        did_opt_in: false,
        email_address: '',
        first_name: null,
        id: 0,
        is_active: false,
        job_title: undefined,
        last_name: undefined,
        linkedin_profile_url: null,
        phone: '',
        professional_email_address: undefined,
        skills: undefined,
        years_experience: undefined,
        updated_at: '',
        industry: undefined,
        is_login_email_verified: false,
        login_email_verified_timestamp: null
    },
    patchCurrentExpertStatus: '',
    getCurrentExpert: async () => {
        const expert = await getCurrentExpert();
        set(() => ({ currentExpert: expert }));
        return expert;
    },
    patchCurrentExpert: async (expert: Expert | PatchCurrentExpertBody) => {
        const res = await patchCurrentExpert(expert);
        set(() => ({ currentExpert: expert }));
        return res;
    },
    sendExpertVerificationCode: async (email): Promise<void> => {
        await sendExpertVerificationCode({'email_address': email});
    },
    activateExpert: async (data: ActivateExpertBody) => {
        await activateExpert(data);
    },
    verifyExpert: async (data: VerifyExpertBody) => {
        await  verifyExpert(data);
    }
}));

export default useExpert;
