import { useCallback, useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { LoginAndSecurityFormData, LoginAndSecurityProps } from './types';
import { useAuthentication } from '@store/index';
import CustomAlert from "@components/atoms/Alert";

export const useOnSubmit = (
    setIsLoading: (isLoading: boolean) => void,
    setGlobalAlertMessage: LoginAndSecurityProps['setGlobalAlertMessage'],
    setShouldShowGlobalAlert: LoginAndSecurityProps['setShouldShowGlobalAlert'],
) => {
    return useCallback(
        (values: LoginAndSecurityFormData) => {
            const { email_address, current_password, new_password } = values;

            setIsLoading(true);
            setShouldShowGlobalAlert(false);

            return (
                useAuthentication
                    .getState()
                    .confirmChangeCredentials(email_address, current_password, new_password)
                    .then(() => {
                        setGlobalAlertMessage({
                            text: <CustomAlert title='Success' message='The password has been updated successfully.' type="success" />,
                            type: 'success',
                        });
                    })
                    .catch(() => {
                        setGlobalAlertMessage({
                            text: <CustomAlert title='Error updating password' message='The current password is incorrect.' type="error" />,
                            type: 'error',
                        });
                    })
                    .finally(() => {
                        setIsLoading(false);
                        setShouldShowGlobalAlert(true);
                    })
            );
        },
        [setIsLoading, setGlobalAlertMessage, setShouldShowGlobalAlert],
    );
};

export const useOnCancel = (
    setValue: UseFormSetValue<LoginAndSecurityFormData>,
    initialFormValues: LoginAndSecurityFormData,
    setShouldShowGlobalAlert: LoginAndSecurityProps['setShouldShowGlobalAlert'],
) => {
    return useCallback(() => {
        (Object.entries(initialFormValues) as [keyof LoginAndSecurityFormData, never][]).forEach(([name, value]) => {
            setValue(name, value, { shouldValidate: false });
        });
        setShouldShowGlobalAlert(false);
    }, [setValue, initialFormValues, setShouldShowGlobalAlert]);
};

export const useOnMount = (
    setShouldShowGlobalAlert: LoginAndSecurityProps['setShouldShowGlobalAlert'],
) => {
    return useEffect(() => {
        setShouldShowGlobalAlert(false);
    }, [setShouldShowGlobalAlert]);
};

export const useOnUnmount = (
    setShouldShowGlobalAlert: LoginAndSecurityProps['setShouldShowGlobalAlert'],
) => {
    return useEffect(() => {
        return () => {
            setShouldShowGlobalAlert(false);
        };
    }, [setShouldShowGlobalAlert]);
};