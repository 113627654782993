import { Routes, Route, Navigate } from 'react-router-dom';
import { ROUTES } from './constants';
import {
    CustomEmailAction,
    ForgotPassword,
    Profile, Security,
    NotFound,
    Support,
    SignUp,
    CheckEmail,
    CompleteProfile,
    VerifyProfessionalEmail,
    Login, About
}
    from "@pages/index";
import AuthGuard from "@routes/guards/AuthGuard.tsx";
import VerifiedGuard from "@routes/guards/VerifiedGuard.tsx";
import LandingPageGuard from "@routes/guards/LandingPageGuard.tsx";

function ErrorPage() {
    return null;
}

const AppRoutes = () => (
    <Routes>
        <Route path={ROUTES.ROOT} element={<Navigate to={ROUTES.EXPERT.PATHS.AUTH_LOGIN} replace />} />
        <Route path={ROUTES.EXPERT.PATHS.AUTH_LOGIN} element={
            <LandingPageGuard>
                <Login />
            </LandingPageGuard>
            }
        />
        <Route path={ROUTES.EXPERT.PATHS.AUTH_SIGN_UP} element={<SignUp />} />
        <Route path={ROUTES.EXPERT.PATHS.AUTH_CHECK_EMAIL} element={<CheckEmail />} />
        <Route
            path={ROUTES.EXPERT.PATHS.AUTH_COMPLETE_PROFILE}
            element={
                <AuthGuard>
                    <VerifiedGuard>
                        <CompleteProfile />
                    </VerifiedGuard>
                </AuthGuard>
            }
        />
        <Route path={ROUTES.EXPERT.PATHS.AUTH_VERIFY_PROFESSIONAL_EMAIL} element={<VerifyProfessionalEmail />} />
        <Route path={ROUTES.EXPERT.PATHS.AUTH_FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTES.EXPERT.PATHS.AUTH_EMAIL_ACTION} element={<CustomEmailAction />} />
        <Route
            path={ROUTES.EXPERT.PATHS.ACCOUNT_PROFILE}
            element={
                <AuthGuard>
                    <VerifiedGuard>
                        <Profile />
                    </VerifiedGuard>
                </AuthGuard>
            }
        />
        <Route
            path={ROUTES.EXPERT.PATHS.ACCOUNT_SECURITY}
            element={
                <AuthGuard>
                    <VerifiedGuard>
                        <Security />
                    </VerifiedGuard>
                </AuthGuard>
            }
        />
        <Route path={ROUTES.EXPERT.PATHS.SUPPORT} element={<Support />} />
        <Route path={ROUTES.EXPERT.PATHS.ABOUT} element={<About />} />
        <Route path={ROUTES.EXPERT.PATHS.ERROR} element={<ErrorPage />} />
        <Route path="*" element={<NotFound />} />
    </Routes>
);

export default AppRoutes;