import { FC, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { Button, Typography, Box } from '@mui/material';
import { useActivePage, useIsMobile } from '@hooks/index';
import { PageContainer, NewPasswordInput } from '@components/index';
import {
    useUnmount,
    useSubmit,
} from './hooks';
import { ResetPasswordSecondPageProps, ResetPasswordSecondPageFormData } from './types';
import styles from './resetPasswordSecondPage.module.scss';

export const NewPassword: FC<ResetPasswordSecondPageProps> = (props) => {
    const { setGlobalAlertMessage, setShouldShowGlobalAlert, code } = props;
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
    const isMobile = useIsMobile();
    const { control, handleSubmit, watch } = useForm<ResetPasswordSecondPageFormData>({
        defaultValues: { password: '' },
    });
    const [, setActivePage] = useActivePage();
    const onSubmit = useSubmit(setActivePage, setGlobalAlertMessage, setShouldShowGlobalAlert, code);

    useUnmount(setShouldShowGlobalAlert);

    const password = watch('password');

    useEffect(() => {
        setButtonDisabled(!password || !isPasswordValid);
    }, [isPasswordValid, password, setButtonDisabled]);

    return (
        <>
            <PageContainer setShouldShowGlobalAlert={setShouldShowGlobalAlert} isModal={true}>
                <div
                    className={classNames({
                        [styles.resetPasswordSecondPage]: true,
                        [styles['is-mobile']]: isMobile,
                    })}
                >
                    <div className={styles.passwordResetContainer}>
                        <Typography variant='h1' sx={{letterSpacing : "-0.5px"}}>
                            Create a new password
                        </Typography>

                        <form className={styles.form} onSubmit={handleSubmit(onSubmit)} noValidate>
                          <span className={styles.nameLabel}>
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: 'Please enter your new password' }}
                                render={({ field, fieldState }) => (
                                    <Box>
                                        <NewPasswordInput {...field} showValidation={true} label="New password"
                                                          onValidationChange={setIsPasswordValid}
                                                          onChange={(e) => {
                                            field.onChange(e);
                                        }} />
                                        {fieldState.error && (
                                            <Typography color="error" variant="body2">
                                                {fieldState.error.message}
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                            />
                          </span>

                        <Box className={styles.submitWrapper}>
                            <Button
                                disabled={buttonDisabled}
                                className={styles.submit}
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{mt : '30px'}}
                            >
                                SUBMIT
                            </Button>
                            <Button
                                className={styles.submit}
                                variant="text"
                                onClick={() => setActivePage('AUTH_LOGIN')}
                                color="primary"
                                sx={{mt : '16px'}}
                            >
                                GO TO LOG IN
                            </Button>
                        </Box>
                        </form>
                    </div>
                </div>
            </PageContainer>
        </>
    );
};

export default NewPassword;