import styles from './termsAndConditions.module.scss';
import {Typography} from "@mui/material";

const TermsAndConditions = () => {
    return (
        <div className={styles.termsOfConditionsContainer}>
            <Typography variant='h3'>NEWTONX EXPERTS TERMS AND CONDITIONS</Typography>
            <span>Last updated September 12, 2023</span>
            <br />
            <br />
            <Typography variant='h3'>OVERVIEW AND ACTIVITIES</Typography>
            <p>
                NewtonX is a platform aiming at facilitating expertise sharing among professionals. The
                NewtonX experts are professionals and consultants in various industries and specialties
                (“Experts”) who educate and share insights with various professionals interested in their
                expertise (“Clients”) through the NewtonX platform. NewtonX provides opportunities for
                Experts to participate in expertise sharing (“Projects”), including, but not limited to
                telephone consultations and online surveys. An Expert’s participation in any Project with
                any Client is at the Expert’s sole discretion. Experts are never obligated to accept or
                continue any Project and may decline to participate or continue to participate in any
                Project at any time.
            </p>
            <Typography variant='h3'>
                NEWTONX CONDITIONS OF MEMBERSHIP AND PARTICIPATION IN PROJECTS
            </Typography>
            <p>
                You may become an Expert and participate in any particular Project if, and only if, your
                membership and participation would not:
            </p>
            <ol>
                <li>Present any conflict of interest or perceived conflict of interest.</li>
                <li>
                    Cause you to breach any agreement with or other legal obligation to any person or entity
                    (including, but not limited to, your employer, your previous employers or any person or
                    entity to which, or through which, you provide or have provided consulting services).
                </li>
                <li>
                    Cause you to violate any duty or obligation of any kind to, or policy or code of conduct
                    of, any person or entity (including, but not limited to, your employer, your previous
                    employers or any company to which, or through which, you provide or have provided
                    consulting services).
                </li>
                <li>
                    Result in the disclosure of any confidential or proprietary information (including trade
                    secrets) not owned exclusively by you.
                </li>
                <li>
                    Result in any communication or disclosure to any third party of any material nonpublic
                    information concerning any public company or security.
                </li>
                <li>Violate any law, rule or regulation.</li>
            </ol>

            <Typography variant='h3'>
                YOU MUST DECLINE OR DISCONTINUE PARTICIPATION IN ANY PROJECT THAT WOULD RESULT IN A
                VIOLATION OF ANY OF THE CONDITIONS ABOVE.
            </Typography>
            <br />
            <p>As an Expert, you acknowledge and agree to the following:</p>
            <ol>
                <li>You are a non-agent independent contractor of NewtonX</li>
                <li>You have no authority to act on behalf of NewtonX</li>
                <li>
                    You are not eligible for any NewtonX or Client employment benefits based on your
                    participation in the NewtonX Projects
                </li>
                <li>You shall not identify any Client or NewtonX as your employer</li>
                <li>
                    You are joining the NewtonX Projects in your individual capacity and not as a
                    representative or on behalf of any other company or organization (such as past or present
                    employers), except as otherwise agreed in writing between such organization and NewtonX
                </li>
                <li>
                    NewtonX, in its sole discretion, may determine your eligibility for membership in the
                    NewtonX Projects
                </li>
                <li>
                    If you are in any way unsure about your ability to comply with these Terms & Conditions,
                    then you must decline to participate as an Expert.
                </li>
                <li>You agree that NewtonX may share anonymized results of consultations and surveys</li>
                <li>You agree that your consultation may be recorded</li>
            </ol>
            <p>
                You are solely responsible for determining whether you are permitted to join NewtonX and to
                participate in Projects. Before applying to join NewtonX, you must review any and all legal
                agreements that could restrict your ability to participate, including, without limitation,
                employment agreements, consulting agreements, non-disclosure agreements, employee handbooks,
                codes of ethics and similar employment policies. If your employer or client agreement
                forbids you from participating in expert work such as the one offered by NewtonX, you also
                confirm that you have obtained consents or waivers from your clients, employers or any other
                third parties to ensure that you are permitted to act as an Expert.
            </p>
            <Typography variant='h3'>YOUR CURRENT COMPANY AND COMPETITORS</Typography>
            <p>
                You agree not to consult about a company for which you are a current employee, director,
                trustee, officer, or board member (or for which you hold a similar position). You further
                agree that if you were previously employed in the finance or accounting department of a
                company, you will not consult about that company until four months after termination of your
                employment. If a public company of which you are a current director, trustee, officer, board
                member or employee (or for which you hold a similar position) is the target or bidder in a
                tender offer, or has filed to issue an IPO, you must decline all Projects until the tender
                offer is resolved or the IPO is issued. You agree not to consult on a Project for a Client
                that is a direct competitor of a company for which you are a current director, trustee,
                officer, board member or employee (or for which you hold a similar position).
            </p>
            <Typography variant='h3'>GOVERNMENT EMPLOYEES</Typography>
            <p>
                If you are a government employee in a government or jurisdiction that forbids government
                employees from participating in outside consulting projects, you may not be an Expert of
                NewtonX or participate in any Project. Otherwise, if you are a government employee, you
                agree not to consult about matters presently regulated by the agency or department in which
                you are employed. Furthermore, you agree not to consult about any legislation, regulation,
                or policy that you are in a position to vote upon or otherwise influence, and you agree not
                to give or receive any item of value from or to another person or entity for the purpose of
                seeking influence or for any other improper purpose.
            </p>
            <Typography variant='h3'>INVESTMENT AND PROFESSIONAL ADVICE</Typography>
            <p>
                You represent that you are not registered as an investment adviser and that you are joining
                NewtonX in your individual capacity and not as an agent or representative of any entity or
                individual (including, without limitation, any investment Expert). You agree not to provide
                any investment advice including, without limitation, advice concerning the value of any
                security or the advisability of buying, selling, or otherwise investing in any security.
            </p>
            <Typography variant='h3'>HEALTHCARE PROFESSIONALS</Typography>
            <p>
                If you are a healthcare professional, you agree not to disclose any patient or other
                information prohibited to be disclosed under The Health Insurance Portability and
                Accountability Act of 1996 (HIPAA) Privacy and Security Rules or other similar laws, rules
                or regulations, or to dispense any medical advice at any time during the course of your
                service as an Expert. You shall promptly notify us if, in your reasonable opinion, a Client
                is seeking to speak to you for any purpose other than to conduct research (e.g., attempting
                to market products or services to you). Furthermore, you certify to NewtonX that you are
                duly licensed in any jurisdictions in which you are currently practicing, and that you are
                not listed on the List of Excluded Individuals/Entities issued by the Office of the
                Inspector General of the U.S. Department of Health and Human Services and/or the Debarment
                List of the U.S. Food and Drug Administration. You agree that you will promptly notify
                NewtonX in the event that any of the representations in this paragraph cease to be true.
            </p>

            <Typography variant='h3'>CLINICAL TRIALS AND CONSULTING</Typography>
            <p>
                If you have participated in a clinical trial or provided consulting services to a person or
                entity, you may have duties or obligations to treat certain related information as
                confidential. You must satisfy those duties and obligations and any other policies and
                requirements of such person or entity, your institution(s) and trial sponsors. Under no
                circumstances may you ever disclose or discuss any confidential information learned in the
                course of your consulting or clinical trial participation, including patient experience
                information and trial results, until such information has been made public. If you are a
                Data Safety Monitoring Board or Clinical Trial Steering Committee member on an ongoing
                clinical trial, you may not discuss the ongoing trial phase until it is completed and the
                results are made public.
            </p>

            <Typography variant='h3'>ATTORNEYS AND AUDITORS</Typography>
            <p>
                If you are an attorney, you agree not to consult about any Clients you currently represent
                or to disclose any information or opinions that would breach any legal or ethical duty you
                or your firm owes to past or present Clients. If you are currently an auditor or formerly an
                auditor, you agree not to consult about companies or other organizations that you currently
                audit or have audited within the last three years. If you are an attorney or an auditor in
                current practice, you represent that you are duly licensed to practice in the jurisdictions
                in which you currently practice, and that you are in good standing as a member of the Bar or
                other similar professional boards to which you are subject. You agree not to provide any
                legal or professional services to a Client during a Project for that Client.
            </p>

            <Typography variant='h3'>LEGAL PRIVILEGE</Typography>
            <p>
                In the event that a Client initiates a Project involving its legal counsel, it is possible
                that the Client may wish to assert a claim of legal privilege concerning the Project. Under
                these circumstances, Client’s legal counsel may ask you to enter into a separate written
                engagement agreement with them for your services (an “Engagement Agreement”). Any such
                request may only come through NewtonX, and you may only choose to accept following NewtonX’s
                prior written approval in its sole discretion. Once approved, an Engagement Agreement shall
                control over any inconsistent terms in these Terms & Conditions with respect to your
                relationship and obligations to such Client with respect to the Project. Such Engagement
                Agreement shall not modify your obligations to NewtonX or third parties, under these Terms &
                Conditions or otherwise.
            </p>

            <Typography variant='h3'>FALSE OR MISLEADING INFORMATION AND PRIOR MISCONDUCT</Typography>
            <p>
                You agree never to disseminate false or misleading information or inaccurately represent
                your experience, education, employment, or knowledge to us or any Clients. You represent
                that you have not been convicted of or charged with any felony. You represent that you are
                not, and have not been, the target or subject of any regulatory or enforcement proceeding
                brought by any securities, commodities or other financial regulatory authority,
                self-regulatory organization, or other law enforcement body. You represent that you have not
                been debarred, excluded, suspended or otherwise not permitted to participate in U.S.
                government or state programs, including, without limitation, under the OFAC Specially
                Designated Nationals List or the GSA Excluded Parties List System. Should your status change
                with respect to any of these representations, you shall immediately inform NewtonX.
            </p>
            <Typography variant='h3'>EXPERT TUTORIAL</Typography>
            <p>
                You represent that you have reviewed the NewtonX compliance page, understand NewtonX’s key
                compliance rules, and have completed the{' '}
                <a
                    className={styles.tutorialLink}
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfrRrSJbhHG700eF8GGqAOI3dNXt4L2PP62Y7VWhT8TixQJsQ/viewform"
                >
                    Expert Compliance Training
                </a>
                . In addition, you must represent prior to each consultation that you have completed
                NewtonX’s Expert Compliance Training within 12 months of the consultation.
            </p>
            <Typography variant='h3'>ACCURACY OF INFORMATION</Typography>
            <p>
                You are solely responsible for the accuracy and completeness of information that you provide
                to NewtonX (your “Expert Information”) and you understand that NewtonX, Clients and third
                parties may and will rely on your Expert Information. You agree to provide us with accurate
                and up-to-date biographical information in your application and in connection with any
                further queries, including, without limitation, your current and former employment, and any
                conflicts or restrictions on your ability to consult. Based on further information from you
                or other sources, we may amend your Expert Information and you acknowledge that you are
                under a continuing obligation to monitor the accuracy of your Expert Information and to
                update changes promptly.
            </p>
            <Typography variant='h3'>PRIVACY</Typography>
            <p>
                You agree that NewtonX may collect, synthesize and retain information about you, from
                sources including, without limitation, yourself, your activities through NewtonX, and
                third-party sources (hereafter, “Personal Information”). NewtonX will protect Personal
                Information in accordance with NewtonX’s Privacy Policy, which is incorporated by reference.
                NewtonX will not sell Personal Information to third-party marketers or similar
                organizations, or disclose the Personal Information publicly, unless you provide consent or
                we determine that it is appropriate in response to a law, regulation, rule, government
                request or legal process. If your membership in NewtonX ends, you agree that NewtonX may
                preserve Personal Information, provided that NewtonX accords such Personal Information the
                protections set forth in its then-current Terms & Conditions privacy policies. NewtonX also
                commits to let you ask for deletion of your personal information. Notwithstanding the
                latter, you agree that NewtonX may use and share Personal Information in order to, without
                limitation, contact you concerning your participation in NewtonX, manage your membership in
                the NewtonX community, administer compliance policies and procedures, including obtaining
                required approvals and consents for Project participation, market NewtonX ’s services, and
                comply with applicable laws and requests from government authorities. As part of its
                compliance screening, NewtonX reserves the right to conduct background checks on you, itself
                or utilizing third-party services, and to confirm your professional and educational history.
                You agree that we may disclose Personal Information as required by law, to protect NewtonX’s
                rights, or for the prevention, detection or disclosure of a crime. You also recognize that
                Clients may be required by law or their compliance policies to disclose Personal Information
                about their Projects with you and you authorize NewtonX and its Clients to make such
                disclosures.
            </p>
            <Typography variant='h3'>APPLICATION OF THE TERMS & CONDITIONS</Typography>
            <p>
                By becoming an Expert, you represent and warrant to us that you are in compliance with each
                of the conditions, agreements, acknowledgments, representations, covenants, warranties and
                other obligations included in these Terms & Conditions. As an Expert, you will be expected
                to provide information during any Project in good faith, to the best of your ability and at
                all times consistent with these Terms & Conditions. You understand and acknowledge that each
                NewtonX employee, affiliate and Client is an intended third-party beneficiary of these Terms
                & Conditions and shall therefore have the right to enforce your compliance with them. You
                must decline to participate in, or to continue your participation in, any Project or other
                activity that would cause you to violate these Terms & Conditions and immediately inform us
                of the reason. We typically do not participate in your interactions with Clients and shall
                not be liable or responsible to you in any manner whatsoever for Projects arranged by us.
                You are solely responsible for ensuring your continued compliance with the Terms &
                Conditions. You agree to cooperate fully in any inquiry concerning actual, alleged, or
                potential violations of these Terms & Conditions. To encourage your vigilance and care in
                complying with these Terms & Conditions, you may submit a payment request for a full Project
                that you choose to terminate early for the purpose of complying with them.
            </p>
            <Typography variant='h3'>CONFIDENTIALITY</Typography>
            <p>
                You shall keep and maintain as strictly confidential, and agree not to communicate, reveal
                or disclose, all “Confidential Information” of NewtonX or its Clients, without NewtonX’s
                expressly written permission (which it may withhold in its sole discretion). “Confidential
                Information” includes any and all nonpublic information, in any form or medium, written,
                oral or otherwise, concerning or relating to NewtonX, any of its affiliates or any Client,
                including, but not limited to, the names of any Clients and Clients’ employees, questions
                posed by any Client and the topics discussed, information or materials shared, opinions
                expressed or work commissioned by NewtonX or any Client. In the event that you are required
                to disclose Confidential Information under applicable law, you agree to promptly notify
                NewtonX and to fully cooperate with NewtonX in exercising any right or exemption available
                to prevent the disclosure of Confidential Information to the extent permitted under
                applicable law. You shall not use Confidential Information for any purpose other than those
                specifically permitted by these Terms & Conditions. Without limiting the foregoing, you
                shall not use Confidential Information for personal benefit (including for the purpose of
                making investment or trading decisions). The duty to maintain confidentiality hereunder
                shall survive until the Confidential Information becomes generally known to and available
                for use by the public other than as a result of a disclosure by you or any other person
                under legal obligation not to disclose the information.
            </p>
            <Typography variant='h3'>PROJECTS</Typography>
            <p>
                You shall only accept Projects with NewtonX Clients individually arranged or explicitly
                approved by NewtonX. If a Client requests additional work or Projects, you must receive
                explicit approval from us before commencing work. You will not be paid for any Project with
                a NewtonX Client that was not individually arranged or explicitly approved by NewtonX in
                advance, even if the Project was a follow-up to a Project that was arranged by NewtonX. If
                you are invited by NewtonX to perform a Project for a Client, you should either accept or
                decline the invitation within 24 hours of issuance. We make no representation regarding the
                frequency or quantity of Project requests that you will receive. We expect you to perform
                all Projects in a timely, diligent and professional manner consistent with highest industry
                practice.
            </p>
            <Typography variant='h3'>PAYMENT</Typography>
            <p>
                You will be paid based on your customary consulting fees, as agreed by you and us. You may
                only change your consulting fee rates upon written notice to us and the new fee rate is only
                applicable to Projects that occur after our receipt and approval of written notice. You may
                not reveal your billing rates and financial arrangements to Clients without NewtonX’s prior
                written consent. Our payment system may accommodate consulting guidelines set forth by an
                Expert’s employer, including joint payment to the Expert and his or her employer, direct
                payment to the Expert’s institution or payment to a charity of the Expert’s choice. Experts
                will receive a U.S. I.R.S. Form 1099, as required. Payments will be made following the
                completion of an Expert’s consulting engagement, within 30 days.
            </p>
            <Typography variant='h3'>INDEPENDENT CONTRACTOR STATUS</Typography>
            <p>
                It is understood and agreed that any services performed by you under these Terms &
                Conditions shall be performed as an independent contractor and consultant, and you further
                understand and acknowledge that you have no authority to act or speak on NewtonX’s behalf or
                to represent that you have any such authority. You shall not be deemed to be an employee of
                NewtonX or any of its Clients and you shall not be entitled to any benefits provided by
                either NewtonX or any of its Clients. You agree that you shall be personally responsible and
                liable for any and all taxes and other payments due on payments received by you for services
                provided under these Terms & Conditions. Nothing in these Terms & Conditions shall create a
                partnership or joint venture or establish the relationship of principal and agent or any
                other relationship of a similar nature between you, NewtonX or its Clients.
            </p>
            <Typography variant='h3'>TERMINATION</Typography>
            <p>
                You may terminate your membership in NewtonX at any time by providing us with written
                notice. Likewise, NewtonX may terminate your membership in NewtonX at any time and for any
                reason without providing notice. Termination shall not relieve either party of its
                obligations under these Terms & Conditions intended to survive termination, which include,
                but are not limited to: Privacy, Application of the Terms and Conditions, Confidentiality,
                Non-Solicitation, Intellectual Property, Indemnification, Limitation of Liability,
                Arbitration, Governing Law and Venue and Miscellaneous.
            </p>
            <Typography variant='h3'>NON-SOLICITATION</Typography>
            <p>
                You agree that for a period of one year from the completion of any Project with a Client to
                whom you have been first introduced by us, you will not circumvent NewtonX by knowingly
                soliciting, negotiating with, or entering into any agreement or other arrangement (whether
                written or oral) with a Client first introduced to you by us pursuant to which you would be
                engaged by such Client to provide services of a similar nature to the services, Experts
                provide to NewtonX’s Clients (i.e., short term consulting services) without our prior
                written consent. You also agree that, for a period of one year after the termination of your
                relationship with us for any reason whatsoever, you will not, directly or indirectly,
                solicit for employment, employ or hire any employee of NewtonX or of any Clients to whom you
                have been first introduced by us.
            </p>
            <Typography variant='h3'>INTELLECTUAL PROPERTY</Typography>
            <p>
                You are solely responsible for the content that you share or create in connection with your
                performance of services hereunder. You represent that you own or have obtained any necessary
                licenses or permissions for any materials, regardless of form or format (hereafter, “IP”),
                that you provide to NewtonX, Clients or third parties in connection with your performance of
                such services. IP that you create in connection with any Client Project shall be solely
                owned by the Client who requested the Project and that Client may use such IP for any
                purpose permitted under applicable law and that Client’s agreement with NewtonX. Any
                inventions, discoveries or improvements that are based in any way on any IP you create for a
                Client in the course of a Project, and all intellectual property rights in such inventions,
                discoveries or improvements, shall likewise be owned solely and entirely by the Client. Any
                IP that you created prior to, or independently of, any Project (hereafter, “Expert IP”)
                remains your property. However, with respect to any Expert IP that you share or otherwise
                provide to a Client in connection with a Project, you shall automatically grant that Client
                a perpetual, worldwide, royalty-free, and transferable license to use such Expert IP.
                Furthermore, and without limiting the foregoing, you agree that each Client is free to use
                any ideas, concepts, know-how, or techniques contained in any IP you share with or otherwise
                provide to that Client for any purpose whatsoever, including, but not limited to, devising,
                manufacturing, promoting and selling products and services using such information.
            </p>
            <Typography variant='h3'>INDEMNIFICATION</Typography>
            <p>
                You agree to indemnify and hold harmless NewtonX and its affiliates, and their respective
                officers, directors, employees, members, managing members, managers, agents,
                representatives, successors and assigns (collectively, the “NewtonX Parties”) from any and
                all actions, causes of action, claims, charges, demands, cost, expenses and damages
                (including attorneys’ fees and expenses) and liabilities of any kind whatsoever directly or
                indirectly resulting from, arising out of or in connection with your breach or violation of
                any and all provisions of these Terms and Conditions.
            </p>
            <Typography variant='h3'>LIMITATION OF LIABILITY</Typography>
            <p>
                YOU AGREE THAT UNDER NO CIRCUMSTANCES WILL NEWTONX PARTIES BE LIABLE TO YOU OR TO ANY OTHER
                PARTY FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES ARISING
                FROM OR RELATING TO YOUR SERVICES AND OTHER CONDUCT AS AN EXPERT, OR YOUR INTERACTIONS
                OTHERWISE WITH NEWTONX PARTIES OR CLIENTS. NEWTONX PARTIES SHALL NOT BE LIABLE FOR ANY
                CLAIMS AGAINST YOU BY THIRD PARTIES. IN NO EVENT SHALL THE MAXIMUM CUMULATIVE LIABILITY OF
                NEWTONX PARTIES ARISING FROM OR RELATED TO ANY CONDUCT IN CONNECTION WITH THESE TERMS AND
                CONDITIONS EXCEED THE FEES PAID BY NEWTONX TO YOU HEREUNDER FOR THE RELEVANT CONSULTATION OR
                SURVEY IN THE MONTH SUCH LIABILITY IS ALLEGED TO HAVE ARISEN.
            </p>
            <p>
                NewtonX agrees not to seek to impose upon you liability for indirect, incidental,
                consequential, exemplary or punitive damages based on your performance of services under
                these Terms & Conditions, so long as such damages do not arise out of or relate to a failure
                by you to comply with these Terms & Conditions, or conduct that constitutes gross negligence
                or intentional misconduct. Collectively, the terms in this paragraph constitute the
                “Limitation of Liability.”
            </p>
            <Typography variant='h3'>ARBITRATION, GOVERNING LAW AND VENUE</Typography>
            <p>
                The validity, interpretation, performance and enforcement of these Terms & Conditions shall
                be governed by the laws of the State of New York without regard to the conflicts of laws
                principles thereof.
            </p>
            <p>
                ANY DISPUTE, CONTROVERSY OR CLAIM ARISING FROM OR RELATING TO THESE TERMS AND CONDITIONS
                SHALL BE FULLY AND EXCLUSIVELY FINALLY SETTLED BY AN ARBITRATION HELD IN NEW YORK CITY UNDER
                THE RULES OF THE AMERICAN ARBITRATION ASSOCIATION IN EFFECT FROM TIME TO TIME. YOU HEREBY
                IRREVOCABLY AND UNCONDITIONALLY WAIVE ANY RIGHTS YOU MAY HAVE TO A TRIAL BY JURY WITH
                RESPECT TO ANY SUCH DISPUTE, CONTROVERSY OR CLAIM RELATING TO THIS AGREEMENT. The arbitrator
                may grant any remedy that he or she deems just and equitable within the scope of this
                arbitration agreement, except that he or she may not, under any circumstance, grant a remedy
                inconsistent with or in violation of the Limitation of Liability. The award of the
                arbitrator shall be final and binding and judgment thereon may be entered in any court
                having jurisdiction. You hereby unconditionally and irrevocably submit to the jurisdiction
                of the federal and state courts of New York.
            </p>
            <Typography variant='h3'>MISCELLANEOUS</Typography>
            <p>
                We may modify these terms and conditions from time to time by posting the modification(s) on
                our website, www.NewtonX.com. Unless otherwise specified by us when posted, all
                modifications will be effective upon posting. If you do not agree to any modification(s),
                your recourse will be to terminate your participation as an Expert in NewtonX. If you
                continue to participate as an Expert after any modification becomes effective, then your
                participation will constitute acceptance of such modification. No failure or delay by either
                party in exercising any right, power or privilege hereunder shall operate as a waiver
                thereof, nor shall any single or partial exercise thereof preclude any other or further
                exercise thereof or the exercise of any right, power or privilege hereunder. Neither these
                Terms & Conditions nor any of the rights, interests or obligations hereunder may be
                assigned, delegated or otherwise transferred, in whole or in part, by operation of law or
                otherwise, by you without our prior written consent (which consent we may withhold in our
                sole discretion), and any such assignment, delegation or transfer by you without such prior
                written consent shall be null and void and of no force or effect whatsoever. These Terms &
                Conditions shall be binding upon and inure solely to the benefit of you and NewtonX and the
                successors, assigns and Clients of NewtonX. As used in these Terms & Conditions, the word
                “including” shall mean “including, without limitation,” in all cases. Whenever possible,
                each provision of these Terms and Conditions shall be interpreted in such manner as to be
                effective and valid under applicable law, but if any provision of these Terms & Conditions
                is held to be invalid, illegal or unenforceable in any respect, such invalidity, illegality
                or unenforceability shall not affect any other provision of these Terms & Conditions, and
                these Terms & Conditions shall be reformed, construed and enforced as if such invalid,
                illegal or unenforceable provision had never been contained herein. By signing these Terms &
                Conditions, you acknowledge and agree that you are making express representations to NewtonX
                and Clients that you will abide by all of your obligations and responsibilities as set forth
                in these Terms & Conditions.
            </p>
        </div>
    );
};

export default TermsAndConditions;
