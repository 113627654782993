import {GetInitialFormValues} from "@pages/Profile/types.ts";

export const getInitialFormValues: GetInitialFormValues = (currentExpert) => {
    const {
        first_name = '',
        last_name = '',
        linkedin_profile_url = '',
        country = null,
        company_name = '',
        company_website = '',
        company_size = null,
        job_title = '',
        years_experience = null,
        skills = null,
        city= '',
        state = '',
        zipcode = ''
    } = currentExpert;

    return {
        first_name,
        last_name,
        linkedin_profile_url,
        country,
        company_name,
        company_website,
        company_size,
        job_title,
        years_experience,
        skills,
        city,
        state,
        zipcode
    };
};