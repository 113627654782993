import { useState } from 'react';
import classNames from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { Typography, Button as MuiButton, TextField, Box } from '@mui/material';
import { INPUTTED_LOGIN_EMAIL } from '@shared/constants';
import { PageContainer } from '@components/index';
import { useActivePage, useIsMobile } from '@hooks/index';
import { useUnmount, useSubmit } from './hooks';
import { ResetPasswordFirstPageFormData } from './types';
import { GlobalAlertMessage } from '@models/GlobalAlert';

import styles from './resetPasswordFirstPage.module.scss';

export const ForgotPassword = () => {
  const [shouldShowGlobalAlert, setShouldShowGlobalAlert] = useState(false);
  const [globalAlertMessage, setGlobalAlertMessage] = useState<GlobalAlertMessage | null>(null);
  const isMobile = useIsMobile();
  const { control, handleSubmit, formState: { errors } } = useForm<ResetPasswordFirstPageFormData>({
    defaultValues: { email: localStorage.getItem(INPUTTED_LOGIN_EMAIL) || '' }
  });
  const [isDisabled,] = useState(false);
  const [, setActivePage] = useActivePage();
  const onEmailChange = () => {}
  const onSubmit = useSubmit(setGlobalAlertMessage, setShouldShowGlobalAlert);

  const handleGoToLoginPage = () => {
    setActivePage('AUTH_LOGIN');
  };

  useUnmount(setShouldShowGlobalAlert);

  return (
      <PageContainer isModal={true} setShouldShowGlobalAlert={setShouldShowGlobalAlert} globalAlertMessage={globalAlertMessage} shouldShowGlobalAlert={shouldShowGlobalAlert}>
        <div className={classNames({
          [styles.resetPasswordFirstPage]: true,
          [styles['is-mobile']]: isMobile,
        })}>
          <div className={styles.passwordResetContainer}>
            <Typography variant='h1' fontWeight="bold">
              Reset your password
            </Typography>

            <Typography className={styles.para} variant="body1">
              Enter the email you use to log in and we'll send you a link to reset your password.
            </Typography>

            <Box
                component="form"
                className={styles.form}
                onSubmit={handleSubmit(onSubmit)}
                noValidate
            >
              <Controller
                  name="email"
                  control={control}
                  rules={{ required: 'Email is required', pattern: { value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, message: 'Please enter a valid email address.' } }}
                  render={({ field }) => (
                      <TextField
                          {...field}
                          label="Login email"
                          type="email"
                          variant="outlined"
                          fullWidth
                          error={!!errors.email}
                          helperText={errors.email ? errors.email.message : null}
                          onChange={(e) => {
                            field.onChange(e);
                            onEmailChange();
                          }}
                          sx={{ mb: 2 }}
                      />
                  )}
              />

              <MuiButton
                  disabled={isDisabled}
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  sx={{ height: '40px', fontWeight: 'bold' }}
              >
                RESET PASSWORD
              </MuiButton>
            </Box>

            <MuiButton
                variant="text"
                color="primary"
                onClick={handleGoToLoginPage}
                fullWidth
                sx={{ height: '40px', fontWeight: 'bold', mt: 2 }}
            >
              GO BACK TO LOG IN
            </MuiButton>
          </div>
        </div>
      </PageContainer>
  );
};

export default ForgotPassword;