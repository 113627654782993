import classNames from 'classnames';

import {useIsMobile} from '@hooks/index';

import { PageContainer } from '@components/index';

import styles from './support.module.scss';
import {Typography} from "@mui/material";
import {useAuthentication} from "@store/index.ts";

export const Support = () => {
    const isMobile = useIsMobile();
    const { isAuthenticated } = useAuthentication();

    return (
        <>
            <PageContainer isModal={!isAuthenticated} hasLeftSection={isAuthenticated} selectedMenuItem={'support'}
            >
                <div className={classNames(styles.container, {
                    [styles['is-mobile']]: isMobile,
                })}>

                    <Typography variant='h1' fontWeight="bold" textAlign="start" mb={'30px'}>
                        Support
                    </Typography>
                    <Typography variant="body1">
                        For any questions regarding your account, reach out to us at{' '}
                        <a className={styles.email} href="mailto:professionals@newtonx.com">professionals@newtonx.com</a>.
                    </Typography>
                </div>
            </PageContainer>
        </>
    );
};

export default Support;
