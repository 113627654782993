import {FC, useEffect, useRef} from 'react';
import {Box, Alert, AlertTitle, useMediaQuery, Theme, styled} from '@mui/material';
import { SxProps, Theme as MuiTheme } from '@mui/material/styles';
import { GlobalAlertMessage } from '@models/GlobalAlert';
import {ExpertProfilePage} from '@shared/constants';
import {scrollToTop} from "@utilities/scrollToTop.ts";
import {useActivePage} from "@hooks/index.ts";
import {ProfileMenu} from "@components/index.ts";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export interface PageContainerProps {
    children: React.ReactNode;
    leftSection?: React.ReactNode;
    rightSection?: React.ReactNode;
    setShouldShowGlobalAlert?: (value: boolean) => void;
    shouldShowGlobalAlert?: boolean;
    isModal?: boolean;
    hasLeftSection?: boolean;
    selectedMenuItem?: string;
    globalAlertMessage?: GlobalAlertMessage | string | null;
}

const PageContainer: FC<PageContainerProps> = (props: PageContainerProps) => {
    const {
        children,
        shouldShowGlobalAlert,
        globalAlertMessage,
        setShouldShowGlobalAlert,
        isModal,
        hasLeftSection,
        selectedMenuItem,
    } = props;

    const isMobile = useMediaQuery((theme: MuiTheme) => theme.breakpoints.down('sm'));
    const [activePage, ] = useActivePage();

    const showBorder = hasLeftSection;
    const topPosition = activePage === 'AUTH_LOGIN' ? '50%' : '138px';
    const topTransform = activePage === 'AUTH_LOGIN' ? 'translate(-50%, -50%)' : 'translate(-50%, 0)'
    const containerSx: SxProps<Theme> = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        justifyContent: 'center',
        padding: isModal ? '44px 54px' : '0',
        width: isModal ? '448px' : '100%',
        position: isModal ? 'absolute' : 'relative',
        top: isModal ? topPosition : 0,
        left: isModal ? '50%' : '0',
        right: '0',
        transform: isModal ? topTransform : 'none',
        marginTop: '0',
        background: 'white',
        borderRadius: '6px',
    };

    const alertWrapperSx: SxProps<Theme> = {
        minWidth: '350px',
        maxWidth: '486px',
        marginBottom: '16px',
        position: 'absolute',
        zIndex: 100,
        top: isModal ? '-90px' : '10px'
    };

    const alertMessageSx: SxProps<Theme> = {
        fontWeight: 'bold',
    };

    const leftSectionSx: SxProps<Theme> = {
        display: hasLeftSection && !isMobile ? 'block' : 'none',
        width: '250px',
        paddingTop: '16px',
        paddingBottom: '16px',
    };

    const middleSectionSx: SxProps<Theme> = {
        flex: 1,
        width: '100%',
        paddingLeft: showBorder ? '60px' : '0px',
        maxHeight: showBorder ? 'calc(100svh - 94px)' : 'auto',
        overflow: showBorder ? 'scroll' : 'auto'
    };

    const borderSx : SxProps<Theme> = {
        background: '#D4D5D5',
        height: '100%',
        position: 'absolute',
        left: '275px',
        width: '1px'
    }

    let alert = null;
    const refDivAfterMessages = useRef<HTMLDivElement>(null);

    const CustomAlert = styled(Alert)(() => ({
        '& .MuiAlert-icon': {
            marginRight: '8px',
            display: 'block',
        },
    }));

    useEffect(() => {
        if (shouldShowGlobalAlert) {
            scrollToTop(refDivAfterMessages);
        }
    }, [shouldShowGlobalAlert]);

    const getAlertBackgroundColor = (type : string) => {
        if (type === 'success') {
            return '#F4FCF8';
        } else if (type === 'info') {
            return '#E4F5F9'
        }
        return '';
    }

    const getAlertIcon = (type : string) => {
        if (type === 'success') {
            return <CheckCircleOutlinedIcon
                style={{marginRight: '6px', fill: '#3C5A4B'}}
            />
        } else if (type === 'error') {
            return <CancelOutlinedIcon
                style={{marginRight: '6px', fill: '#692620'}}
            />
        }
        return <InfoOutlinedIcon
            style={{marginRight: '6px', fill: '#262B2D'}}
        />
    }

    if (setShouldShowGlobalAlert && shouldShowGlobalAlert && globalAlertMessage && typeof globalAlertMessage !== 'string') {
        alert = (
            <Box ref={refDivAfterMessages} sx={alertWrapperSx}>
                <CustomAlert
                    severity={globalAlertMessage.type}
                    icon={getAlertIcon(globalAlertMessage.type)}
                    sx={{background: getAlertBackgroundColor(globalAlertMessage.type), borderRadius: '8px' }}
                    onClose={() => setShouldShowGlobalAlert(false)}
                >
                    <AlertTitle sx={alertMessageSx}>{globalAlertMessage.text}</AlertTitle>
                </CustomAlert>
            </Box>
        );
    }

    return (
        <div style={{height: isModal? 'calc(100vh - 64px)' : 'auto'}} ref={refDivAfterMessages}>
            <Box sx={containerSx}>
                {alert}
                {hasLeftSection && selectedMenuItem && !isMobile && <Box sx={leftSectionSx}>
                    <div style={{padding: '30px',
                        paddingTop: '0'
                    }}>
                        <ProfileMenu selectedMenuItem={selectedMenuItem as ExpertProfilePage}/>
                    </div>
                </Box>}
                {showBorder && <Box sx={borderSx}></Box>}
                <Box sx={middleSectionSx}>{children}</Box>
            </Box>
        </div>
    );
};

export default PageContainer;