import { createTheme } from '@mui/material/styles';
import {alpha} from "@mui/material";

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: '#0F4BFF',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#262B2D',
            contrastText: '#FFFFFF',
        },
        info: {
            main: '#BBE6F0',
            contrastText: '#000'
        },
        error: {
            main: '#D12249',
        },
    },
    typography: {
        fontFamily: 'DM Sans',
        h1: {
            fontSize: '32px',
            fontWeight: 'bold',
            lineHeight: 1.2,
        },
        h2: {
            fontSize: '24px',
            fontWeight: 'bold',
            lineHeight: 1.1,
        },
        h3: {
            fontSize: '20px',
            fontWeight: 'bold',
            lineHeight: 1.0,
        },
        h4: {
            fontSize: '18px',
            fontWeight: 'bold',
            lineHeight: 1.0,
        },
        body1: {
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: 1.5,
        },
        body2: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: 1.43,
        },
        subtitle1: {
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: 1.75,
        },
        subtitle2: {
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: 1.57,
        },
        button: {
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: 1.75,
            textTransform: 'none'
        },
        caption: {
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: 1.66,
        },
        overline: {
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: 2.66,
            textTransform: 'uppercase',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    fontWeight: 700,
                    boxShadow: 'none',
                    height: '40px',
                    borderRadius: '8px',
                    ...(ownerState.variant === 'outlined' &&
                        ownerState.color === 'primary' && {
                            borderOpacity: 0.5,
                        }),
                }),
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& fieldset': {
                        color: '#474B4C',
                        borderRadius: '8px',
                        borderColor: alpha('#000000', 0.23), // Default border color
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        color: '#262B2D',
                        borderColor: '#000000', // Border color on hover
                    },
                },
            },
        },
    },
});

export default theme;