import axios, {AxiosResponse} from 'axios';
import {
    notification,
    formatHTTPErrorTitle,
    formatHTTPErrorMessage,
} from '@utilities/notifications/notifications';

import { addPendingRequest, cancelPendingRequest, removePendingRequest } from './helpers';

import {
    InterceptSuccessResponse,
    InterceptErrorResponse,
    InterceptSuccessRequest,
    AxiosCreate,
    NXAxiosRequestConfig,
} from './types';
import { useAuthentication } from '@store/index';

const {
    VITE_REACT_APP_API_ENDPOINT_EXPERT,
    VITE_REACT_APP_API_ENDPOINT_SURVEY,
    VITE_REACT_APP_API_ENDPOINT_EXPERT_VERIFY_PUBLIC,
    VITE_REACT_APP_API_ENDPOINT_EXPERT_VERIFY_PUBLIC_LEGACY,
    VITE_REACT_APP_API_ENDPOINT_COSMOS_LOCATION,
    VITE_REACT_APP_API_ENDPOINT_COSMOS_REFERENCE,
    VITE_REACT_APP_FIREBASE_AUDIENCE_EXPERT_PORTAL,
} = import.meta.env;

const CANCELLED_REQUEST_ERROR_MESSAGE = 'Cancelled pending request';

export const DEFAULT_API_TIMEOUT = 60000;

export const interceptSuccessResponse: InterceptSuccessResponse = (response) => {
    return response.data;
};

export const interceptErrorResponse: InterceptErrorResponse = (error) => {
    const statusCode = error.response?.status;

    if (statusCode === 401 || statusCode === 403) {
        console.dir(error);
        console.error(error);
        notification({
            type: 'error',
            title: formatHTTPErrorTitle(error),
            description: formatHTTPErrorMessage(error),
        });
        const token = localStorage.getItem(
            VITE_REACT_APP_FIREBASE_AUDIENCE_EXPERT_PORTAL as string,
        ) as string;
        //Token expired
        if (token && token !== '') {
            useAuthentication.getState().logout();
            //window.location.reload();
        }
        return Promise.reject(error);
    }

    if ([undefined, 500, 502, 503].includes(error?.response?.status)) {
        if (error.message !== CANCELLED_REQUEST_ERROR_MESSAGE) {
            notification({
                type: 'error',
                title: formatHTTPErrorTitle(error),
                description: formatHTTPErrorMessage(error),
            });
        }

        console.dir(error);
        console.error(error);
        return Promise.reject(error);
    }
    notification({
        type: 'error',
        title: formatHTTPErrorTitle(error),
        description: formatHTTPErrorMessage(error),
    });
    console.dir(error);
    console.error(error);
    return Promise.reject(error);
};

export const interceptSuccessRequest: InterceptSuccessRequest = (request) => {
    // TODO: need to determine which audience token to use by looking at the base url.
    const accessToken = localStorage.getItem(
        VITE_REACT_APP_FIREBASE_AUDIENCE_EXPERT_PORTAL as string,
    );

    if (accessToken && request.headers) request.headers.Authorization = `Bearer ${accessToken}`;

    return request;
};

export const interceptorHandleCancellablePendingResponse = (config: AxiosResponse) => {
    removePendingRequest(config as NXAxiosRequestConfig);

    return config;
};

export const interceptorHandleCancellablePendingError: InterceptErrorResponse = (error) => {
    if (!axios.isCancel(error)) {
        //@ts-expect-error axios inferred type
        removePendingRequest(error.config);
    }

    return Promise.reject(error);
};

export const interceptorHandleCancellablePendingRequests = (config: NXAxiosRequestConfig) => {
    cancelPendingRequest(config);
    removePendingRequest(config);
    addPendingRequest(config);

    return config;
};

export const axiosCreate: AxiosCreate = (params) => {
    const { baseURL, withCredentials = false } = params;

    const instance = axios.create({
        baseURL,
        timeout: DEFAULT_API_TIMEOUT,
        withCredentials,
    });

    instance?.interceptors.response.use(interceptSuccessResponse, interceptErrorResponse);

    instance?.interceptors.response.use(
        interceptorHandleCancellablePendingResponse,
        interceptorHandleCancellablePendingError,
    );

    instance?.interceptors.request.use(interceptSuccessRequest);
    instance?.interceptors.request.use(interceptorHandleCancellablePendingRequests);

    return instance;
};

export const expertAPI = axiosCreate({ baseURL: VITE_REACT_APP_API_ENDPOINT_EXPERT });
export const expertPublicAPI = axiosCreate({
    baseURL: VITE_REACT_APP_API_ENDPOINT_EXPERT_VERIFY_PUBLIC,
});
export const expertPublicLegacyAPI = axiosCreate({
    baseURL: VITE_REACT_APP_API_ENDPOINT_EXPERT_VERIFY_PUBLIC_LEGACY,
});
export const surveyAPI = axiosCreate({ baseURL: VITE_REACT_APP_API_ENDPOINT_SURVEY });

export const locationAPI = axiosCreate({
    baseURL: VITE_REACT_APP_API_ENDPOINT_COSMOS_LOCATION,
    withCredentials: true,
});

export const referenceAPI = axiosCreate({
    baseURL: VITE_REACT_APP_API_ENDPOINT_COSMOS_REFERENCE,
    withCredentials: true,
});
