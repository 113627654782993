import { FC } from 'react';
import { Props } from './types';
import {Grid, Typography, Box} from "@mui/material";

const CustomAlert: FC<Props> = (props) => {
    const { title, message, type } = props;
    let color = '#262B2D'
    if (type === 'success') {
        color = '#3C5A4B'
    } else if (type === 'error') {
        color = '#692620'
    } else {
        color = '#262B2D' //default
    }
    return (
        <Box sx={{zIndex: 100}}>
            <Grid container style={{flexDirection: 'column', paddingTop : '0px'}} spacing={1}>
                { title && <Grid item>
                    <Typography variant="body1" sx={{fontWeight: 'bold', color: color}}>
                        {title}
                    </Typography>
                </Grid>}
                { message && <Grid item>
                    <Typography variant="subtitle2" sx={{color: color}}>
                        {message}
                    </Typography>
                </Grid> }
            </Grid>
        </Box>
    );
};

export default CustomAlert;