import {useAuthentication} from "@store/index";
import {useEffect, useCallback} from "react";
import {GlobalAlertMessage} from "@models/GlobalAlert";
import {RegistrationPageFormData} from "@models/Registration";
import {useActivePage} from "@hooks/index";
import CustomAlert from "@components/atoms/Alert";

export const useOnSubmit = (
    setGlobalAlertMessage:  React.Dispatch<React.SetStateAction<GlobalAlertMessage | null>>,
    setShouldShowGlobalAlert:  React.Dispatch<React.SetStateAction<boolean>>,
    setIsLoading:  React.Dispatch<React.SetStateAction<boolean>>,
) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setActivePage]= useActivePage();
    return useCallback(
        async (formData: RegistrationPageFormData) => {
            const { email, password, acceptedTerms } = formData;
            setIsLoading(true);
            setShouldShowGlobalAlert(false);
            const result = await useAuthentication.getState().signUp(email, password, acceptedTerms);
            if (!result) {
                setGlobalAlertMessage({
                    text: <CustomAlert title='Error' message='An error occurred. Click "Create Account" to try again.' type='error' />,
                    type: 'error',
                });
                setShouldShowGlobalAlert(true);
                setIsLoading(false);
            } else setActivePage('AUTH_CHECK_EMAIL');
        },
        [setActivePage, setIsLoading, setGlobalAlertMessage, setShouldShowGlobalAlert],
    );
};

export const useOnMount = (
    setShouldShowGlobalAlert:  React.Dispatch<React.SetStateAction<boolean>>,
) => {
    return useEffect(() => {
        setShouldShowGlobalAlert(false);
    }, [setShouldShowGlobalAlert]);
};

export const useOnUnmount = (
    setShouldShowGlobalAlert:  React.Dispatch<React.SetStateAction<boolean>>,
) => {
    return useEffect(() => {
        return () => {
            setShouldShowGlobalAlert(false);
        };
    }, [setShouldShowGlobalAlert]);
};
