import invertObject from "@utilities/invertObject";

const ROOT = '/';
const EXPERT_ROOT = '/experts';
const SUB_ROOTS = {
    AUTH_ROOT: '/auth',
    ACCOUNT_ROOT: '/account',
}

export const ROUTES = {
    ROOT,
    EXPERT: {
        PATHS: {
            ROOT: ROOT,
            AUTH_LOGIN: `${EXPERT_ROOT}${SUB_ROOTS.AUTH_ROOT}/login`,
            AUTH_SIGN_UP: `${EXPERT_ROOT}${SUB_ROOTS.AUTH_ROOT}/registration`,
            AUTH_CHECK_EMAIL: `${EXPERT_ROOT}${SUB_ROOTS.AUTH_ROOT}/check_email`,
            AUTH_COMPLETE_PROFILE: `${EXPERT_ROOT}${SUB_ROOTS.AUTH_ROOT}/complete_profile`,
            AUTH_VERIFY_PROFESSIONAL_EMAIL: `${EXPERT_ROOT}${SUB_ROOTS.AUTH_ROOT}/verify_professional_email`,
            AUTH_FORGOT_PASSWORD: `${EXPERT_ROOT}${SUB_ROOTS.AUTH_ROOT}/forgot_password`,
            AUTH_EMAIL_ACTION: `${EXPERT_ROOT}${SUB_ROOTS.AUTH_ROOT}/email_action`,
            ACCOUNT_PROFILE: `${EXPERT_ROOT}${SUB_ROOTS.ACCOUNT_ROOT}/profile`,
            ACCOUNT_SECURITY: `${EXPERT_ROOT}${SUB_ROOTS.ACCOUNT_ROOT}/security`,
            SUPPORT: `${EXPERT_ROOT}/support`,
            ABOUT: `${EXPERT_ROOT}/about`,
            ERROR: `${EXPERT_ROOT}/error`,
        },
    },
};

export type ExpertPage = keyof typeof ROUTES.EXPERT.PATHS;

export const EXPERT_PATHS = ROUTES.EXPERT.PATHS;

export const EXPERT_PATHS_INVERTED_MAP = invertObject(ROUTES.EXPERT.PATHS);

export const EXPERT_OPEN_PATHS = [
    EXPERT_PATHS.AUTH_LOGIN,
    EXPERT_PATHS.AUTH_FORGOT_PASSWORD,
    EXPERT_PATHS.AUTH_SIGN_UP,
    EXPERT_PATHS.AUTH_CHECK_EMAIL,
    EXPERT_PATHS.AUTH_EMAIL_ACTION,
    EXPERT_PATHS.SUPPORT,
    EXPERT_PATHS.ERROR,
];

export const DEFAULT_PAGE = "AUTH_LOGIN";
