import {Header} from "@components/index";
import classNames from "classnames";

export interface LayoutProps {
    children: React.ReactNode;
    isAuthenticated: boolean;
    canHaveLoginButton: boolean;
    className?: string;
}

const Layout = (props : LayoutProps) => {
    const {children, isAuthenticated, canHaveLoginButton, className} = props;
    return (
        <div className={classNames(className)}>
            <Header isAuthenticated={isAuthenticated} canHaveLoginButton={canHaveLoginButton} />
            {children}
        </div>
    );
};

export default Layout;