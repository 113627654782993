import {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';

import { useIsMobile } from '@hooks/index';

import {PageContainer} from '@components/index';

import styles from './checkEmail.module.scss';
import { useAuthentication } from '@store/index';
import {Button, Typography} from "@mui/material";
import {GlobalAlertMessage} from "@models/GlobalAlert.ts";
import CustomAlert from "@components/atoms/Alert";

export const CheckEmail = () => {
    const isMobile = useIsMobile();
    const hasSentEmail = useRef(false);
    const [shouldShowGlobalAlert, setShouldShowGlobalAlert] = useState(false);
    const [globalAlertMessage, setGlobalAlertMessage] = useState<GlobalAlertMessage | null>(null);
    const email = useRef(localStorage.getItem('current_user'));

    useEffect(() => {
        if (!hasSentEmail.current && email.current) {
            handleSendVerification(email.current);
            hasSentEmail.current = true;
        }
    }, []);

    const handleSendVerification = async (email: string) => {
        await useAuthentication.getState().sendVerificationEmail(email ?? '');
    };

    const resendVerificationEmail = () => {
        const email = localStorage.getItem('current_user')
        useAuthentication.getState().resendVerificationEmail(email ?? '').then(() => {
            setGlobalAlertMessage({
                text : <CustomAlert title="Check your email" message={`An email to check your account has been sent successfully to ${email}`} />,
                type : 'info',
            })
            setShouldShowGlobalAlert(true);
        }
        ).catch(() => {
            setGlobalAlertMessage({
                text : <CustomAlert title="Error sending email" message={`An error occurred while attempting to resend the email. Please, wait 10 minutes to try again.`} type="error"/>,
                type : 'error',
            })
            setShouldShowGlobalAlert(true);
        });
    }

    return (
        <>
            <PageContainer isModal={true} globalAlertMessage={globalAlertMessage} shouldShowGlobalAlert={shouldShowGlobalAlert} setShouldShowGlobalAlert={setShouldShowGlobalAlert} >
                <div
                    className={classNames({
                        [styles.registrationSuccess]: true,
                        [styles['is-mobile']]: isMobile,
                    })}
                >
                    <Typography variant='h1'>
                        Check your email
                    </Typography>

                    <Typography variant='body1' className={styles.para1}>
                        An email with a link to activate your account should arrive in the next 10 minutes.
                    </Typography>

                    <Button
                        color="primary"
                        fullWidth
                        sx={{marginTop: '30px'}}
                        variant="outlined"
                        onClick={resendVerificationEmail}
                    >
                        RESEND LINK
                    </Button>
                </div>
            </PageContainer>
        </>
    );
};

export default CheckEmail;
