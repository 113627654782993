import { create } from 'zustand';
import { auth } from '@configs/index';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  verifyBeforeUpdateEmail,
  updatePassword,
  applyActionCode,
  confirmPasswordReset,
} from 'firebase/auth';

import {
  registerExpert,
  sendExpertVerificationEmail,
  sendChangePasswordEmail,
} from '@services/experts/experts.service';



export type AuthenticationState = {
  isAuthenticated: boolean;
  isVerified: boolean;
  errorLogin: boolean;
  errorSignUp: boolean;
  hasSentEmail: boolean;
  getAccessToken: () => Promise<string>;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => Promise<void>;
  signUp: (email: string, password: string, acceptedTerms: boolean) => Promise<boolean>;
  changePasswordEmail: (email: string) => Promise<void>;
  confirmChangePassword: (code: string, newPass: string) => Promise<void>;
  confirmChangeCredentials: (email: string, pass: string, newPass: string) => Promise<void | unknown>;
  verifyEmail: (code: string) => Promise<void>;
  sendVerificationEmail: (email: string) => Promise<void>;
  resendVerificationEmail: (email: string) => Promise<void>;
};

const useAuthentication = create<AuthenticationState>((set, get) => ({
  isAuthenticated: false,
  isVerified: false,
  errorLogin: false,
  errorSignUp: false,
  hasSentEmail: false,
  getAccessToken: async () => {
    const { VITE_REACT_APP_FIREBASE_AUDIENCE_EXPERT_PORTAL } = import.meta.env;
    const token = localStorage.getItem(
      VITE_REACT_APP_FIREBASE_AUDIENCE_EXPERT_PORTAL as string,
    ) as string;
    const isVerifiedStr = localStorage.getItem('is_verified');

    if (isVerifiedStr === 'true') set(() => ({ isVerified: true }));
    else set(() => ({ isVerified: false }));

    if (token && token !== '') set(() => ({ isAuthenticated: true }));
    else set(() => ({ isAuthenticated: false }));
    return token;
  },
  login: async (email, password) => {
    try {
      const { VITE_REACT_APP_FIREBASE_AUDIENCE_EXPERT_PORTAL } = import.meta.env;
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      localStorage.removeItem('is_verified');
      localStorage.removeItem('current_user');
      if (userCredential.user.emailVerified) {
        localStorage.setItem('is_verified', 'true');
        set(() => ({ isVerified: true }));
      } else {
        localStorage.setItem('is_verified', 'false');
        set(() => ({ isVerified: false }));
      }
      // Get the ID token
      const token = await userCredential.user.getIdToken();
      localStorage.removeItem(VITE_REACT_APP_FIREBASE_AUDIENCE_EXPERT_PORTAL as string);
      localStorage.setItem('current_user', email);
      localStorage.setItem(VITE_REACT_APP_FIREBASE_AUDIENCE_EXPERT_PORTAL as string, token);
      set(() => ({ isAuthenticated: true, errorLogin: false, hasSentEmail: false }));
      return true;
    } catch (error) {
      set(() => ({ errorLogin: true }));
      return false;
    }
  },
  logout: async () => {
    localStorage.clear();
    set(() => ({ isAuthenticated: false }));
  },
  signUp: async (email, password, acceptedTerms) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const token = await user.getIdToken();
      localStorage.removeItem('current_user');
      await registerExpert({ email_address: email, gidp_user_id: user.uid, did_agree_to_terms: acceptedTerms }, token);
      localStorage.setItem('current_user', email);
      set(() => ({ errorSignUp: false, hasSentEmail: false }));
      return true;
    } catch (error) {
      set(() => ({ errorSignUp: true }));
      return false;
    }
  },
  changePasswordEmail: async (email) => {
    try {
      if (!get().hasSentEmail) {
        set(() => ({ hasSentEmail: true }));
        await sendChangePasswordEmail({ email_address: email });
      }
    } catch (error) {
      set(() => ({ hasSentEmail: false }));
    }
  },
  confirmChangePassword: async (code, newPassword) => {
    return confirmPasswordReset(auth, code, newPassword);
  },
  confirmChangeCredentials: async (email, pass, newPass) => {
    const currentEmail = auth.currentUser?.email;
    try {
      const userCredential = await signInWithEmailAndPassword(auth, currentEmail ?? '', pass);
      if (currentEmail && currentEmail !== email && email && email !== '') {
        return verifyBeforeUpdateEmail(userCredential.user, email);
      }
      if (newPass && pass !== newPass) {
        return updatePassword(userCredential.user, newPass);
      }
    }catch (err) {
      throw new Error('Failed to change credentials');
    }
  },
  verifyEmail: async (code) => {
    localStorage.clear();
    set(() => ({ isAuthenticated: false }));
    return applyActionCode(auth, code);
  },
  sendVerificationEmail: async (email) => {
    try {
      const { VITE_REACT_APP_FIREBASE_AUDIENCE_EXPERT_PORTAL } = import.meta.env;
      localStorage.removeItem(VITE_REACT_APP_FIREBASE_AUDIENCE_EXPERT_PORTAL as string);
      set(() => ({ isAuthenticated: false }) );
      if (!get().hasSentEmail) {
        set(() => ({ hasSentEmail: true }));
        await sendExpertVerificationEmail({ email_address: email });
      }
    } catch (error) {
      set(() => ({ hasSentEmail: false }));
    }
  },
  resendVerificationEmail: async (email) => {
    return sendExpertVerificationEmail({ email_address: email });
  }
}));

export default useAuthentication;
