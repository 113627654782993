import { GlobalAlertMessageTypes } from '@models/GlobalAlert';

import infoIconSrc from '@assets/images/info.svg';
import successIconSrc from '@assets/images/success.svg';
import errorIconSrc from '@assets/images/error.svg';
import {CompanySize} from "@models/Expert.ts";


export const BREAKPOINT = 975;
export const INPUTTED_LOGIN_EMAIL = 'inputted-login-email';
export const NAV_ITEMS = [
    {
        key: 'ACCOUNT_PROFILE',
        desc: 'My account',
        children: [
            {
                key: 'ACCOUNT_PROFILE',
                desc: 'My profile',
            },
            {
                key: 'ACCOUNT_SECURITY',
                desc: 'Login & security',
            },
        ],
    },
    {
        key: 'SUPPORT',
        desc: 'Support',
    },
];
export const HEADER_ITEMS = [
    {
        key: 'ABOUT',
        desc: 'About',
    },
    {
        key: 'SUPPORT',
        desc: 'Support',
    },
];
export type THEME = 'light' | 'dark';

export const FROM = {
    VERIFICATION: 'verification',
};

export const GLOBAL_MESSAGE_ICONS: {
    [key in GlobalAlertMessageTypes]: string;
} = {
    info: infoIconSrc,
    error: errorIconSrc,
    success: successIconSrc,
};

export const COMPANY_SIZE_OPTIONS: { label: string; value: CompanySize }[] = [
    {
        label: 'self',
        value: 'Myself Only',
    },
    {
        label: '1 to 10',
        value: '1-10',
    },
    {
        label: '11 to 50',
        value: '11-50',
    },
    {
        label: '51 to 200',
        value: '51-200',
    },
    {
        label: '201 to 500',
        value: '201-500',
    },
    {
        label: '501 to 1000',
        value: '501-1000',
    },
    {
        label: '1001 to 5000',
        value: '1001-5000',
    },
    {
        label: '5001 to 10000',
        value: '5001-10000',
    },
    {
        label: '10k+',
        value: '10001+',
    },
];

export type ExpertProfilePage = 'myProfile' | 'loginAndSecurity';

export const NAME_REGEX = /^[\D\s-]+$/;