import { FC, memo } from 'react';

import { Logo } from '@components/index';

import {
    Box,
    Button,
    AppBar,
    Toolbar,
} from "@mui/material";
import {HEADER_ITEMS} from "@shared/constants.ts";
import useActivePage from "@hooks/useActivePage.ts";
import {ExpertPage} from "@routes/constants.ts";
import {useAuthentication} from "@store/index.ts";

export interface HeaderProps extends React.PropsWithChildren {
    isAuthenticated: boolean;
    canHaveLoginButton?: boolean;
}

export const Header: FC<HeaderProps> = memo((props) => {
    const { isAuthenticated, canHaveLoginButton = false } = props;
    const [activePage, setActivePage] = useActivePage();
    const noBlackBarPages = [
        'AUTH_LOGIN',
        'AUTH_SIGN_UP',
        'AUTH_CHECK_EMAIL',
        'AUTH_FORGOT_PASSWORD',
        'AUTH_EMAIL_ACTION'
    ];

    const showBlackBar = () => {
        if (noBlackBarPages.includes(activePage)) {
            return false;
        }
        return !(activePage === 'SUPPORT' && !isAuthenticated);
    };
    const handleLogOut = () => {
        useAuthentication.getState().logout();
        setActivePage('AUTH_LOGIN');
    }
    return (
        <AppBar component="nav" sx={{ backgroundColor: showBlackBar() ? '#262B2D' : 'transparent', position: 'relative', boxShadow: 'none' }}>
            <Toolbar>
                <Logo isAuthenticated={isAuthenticated}/>
                <Box sx={{ width: "100%", justifyContent: "end",
                    display: "flex",
                    gap: "12px" }}>
                    {HEADER_ITEMS.map((item) => (
                        <Button key={item.key} sx={{ color: showBlackBar() ? 'white' : '#727577', fontWeight: 'bold' }} onClick={() => setActivePage(item.key as ExpertPage)}>
                            {item.desc}
                        </Button>
                    ))}
                    {(canHaveLoginButton && isAuthenticated) && <Button variant="contained" color="primary" sx={{marginTop: '5px', height: '30px'}} onClick={handleLogOut}>
                        LOG OUT
                    </Button>}
                </Box>
            </Toolbar>
        </AppBar>
    );
});

export default Header;
