import { Location } from 'history';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WarningAmberTwoToneIcon from '@mui/icons-material/WarningAmberTwoTone';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import Prompt from '@components/molecules/Prompt/Prompt';

export interface CustomPromptProps {
    when: boolean;
    message?: string;
}

const RouteLeavingGuard = ({ when, message }: CustomPromptProps) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation,] = useState<Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const navigate = useNavigate();

    const closeModal = () => {
        setModalVisible(false);
    };

    const handleConfirmNavigationClick = () => {
        setModalVisible(false);
        setConfirmedNavigation(true);
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            navigate(lastLocation.pathname, { replace: true });
        }
    }, [confirmedNavigation, lastLocation, navigate]);

    return (
        <>
            <Prompt when={when} message={message ?? ""}></Prompt>
            <Dialog
                open={modalVisible}
                onClose={closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <WarningAmberTwoToneIcon sx={{ color: '#f56600' }} />
                    <Typography variant="h6">Exit without saving?</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography id="alert-dialog-description">
                        {message ??
                            'You’re about to leave this page without saving your changes. Are you sure you’d like to proceed?'}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal} color="primary">
                        No
                    </Button>
                    <Button onClick={handleConfirmNavigationClick} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RouteLeavingGuard;