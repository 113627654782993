import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export const About = () => {
    const navigate = useNavigate();
    useEffect(() => {
        window.open('https://www.newtonx.com/for-professionals/', '_blank');
        navigate(-1);
    }, [navigate]);

    return (
        <>
        </>
    );
};

export default About;
