import { useState, useEffect } from 'react';
import {
    Box, Button as MuiButton,
    Typography
} from '@mui/material';
import {useForm, useWatch, Control} from 'react-hook-form';

import {
    useHandleSubmit,
    useOnUnmount,
    useOnMount,
    useIndustryOptions,
} from './hooks';

import { CompleteProfileForm2Props } from './types';
import styles from './completeProfileForm2.module.scss';
import { CompleteProfileForm2Data } from "@pages/Registration/CompleteProfile/types.ts";
import {JobDetailsForm} from "@components/index.ts";
import {EditSettingsFormData} from "@pages/Profile/types.ts";

export const CompleteProfileForm2: React.FC<CompleteProfileForm2Props> = (props) => {
    const {
        setShowNextForm,
        setGlobalAlertMessage, setShouldShowGlobalAlert,
        setCompleteProfileForm2Data, initValuesForm2, setInitValuesForm2
    } = props;

    const [, setLoading] = useState<boolean>(true); // Loading state
    const { control, handleSubmit } = useForm<CompleteProfileForm2Data>(
        { defaultValues: initValuesForm2 }
    );

    const handleFormSubmit = useHandleSubmit(setCompleteProfileForm2Data);
    const industryOptions = useIndustryOptions();

    useOnMount(setGlobalAlertMessage, setShouldShowGlobalAlert, setLoading);
    useOnUnmount(setShouldShowGlobalAlert);

    const values = useWatch({ control });

    useEffect(() => {
        setInitValuesForm2(values as CompleteProfileForm2Data);
    }, [values, setInitValuesForm2]);

    return (
        <Box className={styles.container}>
            <Typography variant='h1' fontWeight="bold" textAlign="left" mb={4} mt={2}>
                Complete your profile
            </Typography>

            <form onSubmit={handleSubmit(handleFormSubmit)} className={styles.form} noValidate>
                <JobDetailsForm
                    control={control as Control<EditSettingsFormData>}
                    industryOptions={industryOptions}
                />
                <MuiButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mb: 2, mt: 2 }}
                >
                    SUBMIT
                </MuiButton>
                <MuiButton
                    variant="text"
                    color="primary"
                    onClick={() => setShowNextForm(false)}
                    fullWidth
                    sx={{ mb: 2 }}
                >
                    GO BACK
                </MuiButton>
            </form>
        </Box>
    );
};

export default CompleteProfileForm2;