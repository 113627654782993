import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const { VITE_REACT_APP_FIREBASE_AUTH_DOMAIN, VITE_REACT_APP_FIREBASE_API_KEY } = import.meta.env;

const firebaseConfig = {
    apiKey: VITE_REACT_APP_FIREBASE_API_KEY,
    authDomain: VITE_REACT_APP_FIREBASE_AUTH_DOMAIN,
};

// Initialize Firebase App
const app = initializeApp(firebaseConfig);

// Get Firebase Auth instance
const auth = getAuth(app);

export { auth };
