import { useNavigate, useLocation } from "react-router-dom";
import { useCallback, useMemo } from "react";
import {
    EXPERT_PATHS,
    EXPERT_PATHS_INVERTED_MAP,
    DEFAULT_PAGE,
    ExpertPage
} from "@routes/constants";

const useActivePage = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const activePage = useMemo(() => {
        try {
            const [, ...pathParts] = pathname.split('/');
            const path = `/${pathParts.join('/')}`;
            return EXPERT_PATHS_INVERTED_MAP[path] as ExpertPage;
        } catch (_) {
            return DEFAULT_PAGE;
        }
    }, [pathname]);

    const setActivePage = useCallback((page: ExpertPage, query?: Record<string, string>) => {
        if (query) {
            const queryParams = new URLSearchParams(query).toString();
            navigate(`${EXPERT_PATHS[page]}?${queryParams}`);
        } else {
            navigate(`${EXPERT_PATHS[page]}`);
        }
    }, [navigate]);

    return [activePage, setActivePage] as [typeof activePage, typeof setActivePage];
};

export default useActivePage;