import { expertAPI, expertPublicAPI } from '@interceptors/axios';

import {
    GetCurrentExpert,
    PostExpert,
    PostExpertData,
    PostExpertResponse,
    PatchCurrentExpert,
    VerifyExpertEmail,
    UploadPdf,
    ActivateExpert,
    VerifyExpert,
    SendExpertVerificationCode,
    ResetExpertPassword,
    RegisterExpert,
    SendExpertVerificationEmail,
    SendChangePasswordEmail,
    CityStateLookup
} from './adapters';

export const getCurrentExpert: GetCurrentExpert = () => {
    return expertAPI.get('/experts/current/');
};

export const postExpert: PostExpert = (data) => {
    return expertAPI.post<PostExpertData, PostExpertResponse>(`/experts/`, data);
};

export const patchCurrentExpert: PatchCurrentExpert = (data) => {
    return expertAPI.patch(`/experts/current/`, data);
};

export const verifyExpertEmail: VerifyExpertEmail = (data) => {
    return expertPublicAPI.post(`/experts:verify/`, data);
};

export const uploadPdf: UploadPdf = (data) => {
    return expertAPI.put(`/experts:upload/`, data);
};

export const registerExpert: RegisterExpert = (data, token) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    const expertId = new URLSearchParams(window.location.search).get("expert_uuid");
    if (expertId) {
        data.expert_uuid = expertId
    }
    return expertAPI.post('/hooks/user_registration', data, config);
};

export const activateExpert: ActivateExpert = (data) => {
    return expertAPI.post('/experts:activate/', data);
};

export const sendExpertVerificationEmail: SendExpertVerificationEmail = (data) => {
    return expertPublicAPI.post('/experts:send_verification_email/', data);
};

export const sendChangePasswordEmail: SendChangePasswordEmail = (data) => {
    return expertPublicAPI.post('/experts:send_password_reset_email/', data);
};

export const sendExpertVerificationCode: SendExpertVerificationCode = (data) => {
    return expertPublicAPI.post('/experts:send_verification_code/', data);
};

export const verifyExpert: VerifyExpert = (data) => {
    return expertPublicAPI.post('/experts:verify_verification_code/', data);
};

export const resetExpertPassword: ResetExpertPassword = (data) => {
    return expertAPI.post('/experts:reset_password/', data);
};

export const cityStateLookup: CityStateLookup = (data) => {
    return expertAPI.post('/city_state_lookup/', data);
}