import {EditSettingsFormData, EditSettingsProps} from "./types";
import {useCallback, useEffect, useMemo} from "react";
import {allowWindowUnload} from "@utilities/windowBeforeUnload";
import {useExpert} from "@store/index.ts";
import {countryList} from "@shared/countries.ts";
import CustomAlert from "@components/atoms/Alert";

const getLocations = (search : string) => {
    const fullList = [...countryList];
    return fullList.filter((item) => {item.toLowerCase().includes(search.toLowerCase())});
}
const resetLocations = () => {
    return [...countryList];
}

export const useOnSearchLocations = () => {
    return useCallback(
        (searchTerm: string) => {
            if (searchTerm) {
                getLocations(searchTerm);
            } else {
                resetLocations();
            }
        },
        [],
    );
};

export const useLocationOptions = () => {
    return useMemo(() => {
        const locations = [...countryList];
        return locations.map((location) => {
            return (
                {label: location, value: location}
        );
        });
    }, []);
};

export const useOnUnmount = (
    setShouldShowGlobalAlert: EditSettingsProps['setShouldShowGlobalAlert'],
    setSaveIsDisabled: (isDisabled: boolean) => void
) => {
    const disableSubmission = useDisableSubmission(setSaveIsDisabled);

    useEffect(() => {
        return () => {
            disableSubmission();
            setShouldShowGlobalAlert(false);
        };
    }, [disableSubmission, setShouldShowGlobalAlert]);
};

export const useOnSubmit = (
    setGlobalAlertMessage: EditSettingsProps['setGlobalAlertMessage'],
    setShouldShowGlobalAlert: EditSettingsProps['setShouldShowGlobalAlert'],
    setSaveIsDisabled: (isDisabled: boolean) => void
) => {
    const disableSubmission = useDisableSubmission(setSaveIsDisabled);

    return useCallback(
        (formData: EditSettingsFormData) => {
            const {
                // countryCodeId, phone,
                first_name,
                country,
                last_name,
                linkedin_profile_url,
                company_name,
                company_website,
                company_size,
                job_title,
                years_experience,
                skills,
                city,
                state,
                zipcode
            } = formData;

            // const phone = stringifyPhoneNumber({ countryCodeId, phone });

            setShouldShowGlobalAlert(false);

            return (
                useExpert.getState().patchCurrentExpert({
                    first_name,
                    country,
                    last_name,
                    linkedin_profile_url,
                    company_name,
                    company_website,
                    company_size,
                    job_title,
                    years_experience,
                    skills,
                    city,
                    state,
                    zipcode
                })
                    .then(() => {
                        setGlobalAlertMessage({
                            text: (
                                <CustomAlert title={'Success'} message={'Changes saved successfully.'} type="success" />
                            ),
                            type: 'success',
                            closable: true,
                            showIcon: false,
                        });
                        disableSubmission();
                    })
                    .catch(() => {
                        setGlobalAlertMessage({
                            text: (
                                <CustomAlert title={'Error'} message={'Your changes could not be saved. Click "Save Changes" to try again.'} type="error" />
                            ),
                            type: 'error',
                        });
                    })
                    .finally(() => {
                        setShouldShowGlobalAlert(true);
                    })
            );
        },
        [setGlobalAlertMessage, setShouldShowGlobalAlert, disableSubmission],
    );
};

export const useOnMount = (
    setShouldShowGlobalAlert: EditSettingsProps['setShouldShowGlobalAlert'],
    setSaveIsDisabled: (isDisabled: boolean) => void
) => {
    const disableSubmission = useDisableSubmission(setSaveIsDisabled);
    useEffect(() => {
        setShouldShowGlobalAlert(false);
        disableSubmission();
    }, [disableSubmission, setShouldShowGlobalAlert]);
};

export const useDisableSubmission = (setSaveIsDisabled: (isDisabled: boolean) => void) => {
    return useCallback(() => {
        setSaveIsDisabled(true);
        allowWindowUnload();
    }, [setSaveIsDisabled]);
};