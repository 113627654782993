import { ChangeEvent, useCallback} from 'react';
import {Record, RecordOf} from 'immutable';

import {
    UPPER_CASE_REGEX,
    LOWER_CASE_REGEX,
    NUMBER_CHAR_REGEX,
    SPECIAL_CHARS_REGEX,
} from './constants';

import {NewPasswordInputProps, PasswordValidationCheck} from './types';

export const useHandleChange = (
    onChange: NewPasswordInputProps['onChange'],
    setPasswordValidationCheck: (passwordValidationCheck: RecordOf<PasswordValidationCheck>) => void,
) => {
    return useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      let hasError = false;

      const defaultPasswordValidationCheck = Record<PasswordValidationCheck>({
        eightCharacterChecked: 'DEFAULT',
        lowerCaseCharacterChecked: 'DEFAULT',
        numberOrSpecialCharacterChecked: 'DEFAULT',
        upperCaseCharacterChecked: 'DEFAULT',
      });

      if (value) {
        const eightCharacterChecked = value.length >= 8 ? 'VALID' : 'INVALID';
        const lowerCaseCharacterChecked = LOWER_CASE_REGEX.test(value) ? 'VALID' : 'INVALID';
        const upperCaseCharacterChecked = UPPER_CASE_REGEX.test(value) ? 'VALID' : 'INVALID';
        const numberOrSpecialCharacterChecked =
          NUMBER_CHAR_REGEX.test(value) || SPECIAL_CHARS_REGEX.test(value) ? 'VALID' : 'INVALID';

        hasError =
          eightCharacterChecked === 'INVALID' ||
          lowerCaseCharacterChecked === 'INVALID' ||
          upperCaseCharacterChecked === 'INVALID' ||
          numberOrSpecialCharacterChecked === 'INVALID';

        const passwordValidationCheck = defaultPasswordValidationCheck({
          eightCharacterChecked,
          lowerCaseCharacterChecked,
          numberOrSpecialCharacterChecked,
          upperCaseCharacterChecked,
        });

        setPasswordValidationCheck(passwordValidationCheck);
      } else {
        setPasswordValidationCheck(defaultPasswordValidationCheck());
      }

      onChange?.(event, hasError);
    },
    [onChange, setPasswordValidationCheck],
  );
};
