// For alerting users before closing the tab,
// useful for unsaved changes
export const beforeUnloadHandler = (event: Event) => {
    // Recommended
    event.preventDefault();

    // Included for legacy support, e.g. Chrome/Edge < 119
    event.returnValue = true;
};

export const preventWindowUnload = () => {
    window.addEventListener('beforeunload', beforeUnloadHandler);
};

export const allowWindowUnload = () => {
    window.removeEventListener('beforeunload', beforeUnloadHandler);
};