import { Box, TextField, Autocomplete, Typography } from '@mui/material';
import {Control, Controller} from 'react-hook-form';
import { NAME_REGEX } from '@shared/constants';
import styles from '@pages/Profile/profile.module.scss';
import { statesList, statesMap } from '@shared/states.ts';
import { validateZipcode } from "@pages/Registration/CompleteProfile/CompleteProfileForm2/hooks.tsx";
import {EditSettingsFormData} from "@pages/Profile/types.ts";
import {Skills} from "@models/Expert.ts";

interface PersonalDetailsFormProps {
    control: Control<EditSettingsFormData>;
    setSelectedCountry: (country: string) => void;
    setValue: (field: keyof EditSettingsFormData, value: string | number | Skills | null | undefined) => void;
    selectedCountry: string;
    locationOptions: { value: string }[];
    showTitles? : boolean;
    showCountry? : boolean;
}

const PersonalDetailsForm: React.FC<PersonalDetailsFormProps> = ({ control, showTitles, setSelectedCountry, setValue, selectedCountry, locationOptions }) => (
    <>
        {showTitles && <Typography variant='h4' className={styles.subHeader}>
            Personal details
        </Typography>}
        <Box className={styles.nameFields}>
            <Box className={styles.nameField}>
                <Controller
                    name="first_name"
                    control={control}
                    rules={{
                        required: 'Please input your first name',
                        pattern: {
                            value: NAME_REGEX,
                            message: 'Numbers are not allowed',
                        },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            {...field}
                            label="First name*"
                            variant="outlined"
                            fullWidth
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                        />
                    )}
                />
            </Box>

            <Box className={styles.nameField}>
                <Controller
                    name="last_name"
                    control={control}
                    rules={{
                        required: 'Please input your last name',
                        pattern: {
                            value: NAME_REGEX,
                            message: 'Numbers are not allowed',
                        },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            {...field}
                            label="Last name*"
                            variant="outlined"
                            fullWidth
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                        />
                    )}
                />
            </Box>
        </Box>

        <Controller
            name="country"
            control={control}
            rules={{ required: 'Country is required' }}
            render={({ field, fieldState }) => (
                <Autocomplete
                    {...field}
                    options={locationOptions.map(option => (option.value))}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    onChange={(_, value) => {
                        field.onChange(value);
                        setValue("state", "");
                        setValue("city", "");
                        setValue("zipcode", "")
                        setSelectedCountry(value ?? '');
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Country*"
                            variant="outlined"
                            sx={{ mb:  selectedCountry === 'United States' || !showTitles ? 2 : 0 }}
                            fullWidth
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                        />
                    )}
                />
            )}
        />

        {selectedCountry === 'United States' && (
            <>
                <Controller
                    name="city"
                    control={control}
                    rules={{ required: 'Please provide your city' }}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                        <TextField
                            {...field}
                            label="City*"
                            sx={{ marginBottom: '16px' }}
                            variant="outlined"
                            fullWidth
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                        />
                    )}
                />

                <Controller
                    name="state"
                    control={control}
                    rules={{ required: 'Please provide your state' }}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                        <Autocomplete
                            {...field}
                            options={statesList}
                            getOptionLabel={(option) => option}
                            isOptionEqualToValue={(option, value) => option === value}
                            onChange={(_, value) => field.onChange(value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="State*"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginBottom: '16px' }}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                />
                            )}
                        />
                    )}
                />

                <Controller
                    name="zipcode"
                    control={control}
                    rules={{ required: 'Please provide your zip code' }}
                    render={({ field, fieldState }) => (
                        <TextField
                            {...field}
                            label='Zip Code*'
                        variant='outlined'
                        fullWidth
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        onChange={async (e) => {
                        field.onChange(e);
                        if (e.target.value.length === 5) {
                        try {
                        const validated = await validateZipcode(e.target.value);
                        const city = validated.city;
                        const state = statesMap[validated.state];
                        setValue('state', state);
                        setValue('city', city);
                    } catch (error) {
                        console.error('failed to validate zipcode:', error);
                    }
                    }
                    }}
                        />
                        )}
                />
            </>
        )}
    </>
);

export default PersonalDetailsForm;