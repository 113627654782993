import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthentication } from '@store/index.ts';
import { ROUTES } from '../constants';

interface VerifiedGuardProps {
    children: ReactNode;
}

const VerifiedGuard = ({ children }: VerifiedGuardProps) => {
    const isVerified = useAuthentication((state) => state.isVerified);

    if (!isVerified) {
        // Redirect to the check email page if the user is not authenticated
        return <Navigate to={ROUTES.EXPERT.PATHS.AUTH_CHECK_EMAIL} replace />;
    }
    // Render the children if the user is authenticated
    return <>{children}</>;
};

export default VerifiedGuard;