import { isImmutable } from 'immutable';
import { Snackbar, Alert, AlertTitle } from '@mui/material';
import { List, fromJS } from 'immutable';
import { AxiosError } from 'axios';
import {
    CheckCircle as CheckCircleIcon,
    Info as InfoIcon,
    Warning as WarningIcon,
    Error as ErrorIcon,
} from '@mui/icons-material';

export interface NotificationParams {
    type: 'success' | 'info' | 'warning' | 'error';
    description: string;
    title?: string;
    open?: boolean;
    onClose?: () => void;
}

export const formatValidationErrorMessage = (error: { errorFields: { errors: string[] }[] }) => {
    let message = '';
    error.errorFields.forEach((e) => {
        const errors = e.errors || [];
        errors.forEach((m) => {
            message += m + '\n';
        });
    });
    return message;
};

export const formatHTTPErrorTitle = (error: AxiosError) => {
    const statusCode = error.response?.status;
    if (!statusCode) {
        return 'Connectivity Lost';
    }
    return `HTTP Error: ${statusCode}`;
};

export const formatHTTPErrorMessage = (error: AxiosError) => {
    let message = error.message;
    const data = fromJS(error?.response?.data || {});
    if (isImmutable(data) && !data?.isEmpty()) {
        const array: string[] = [];
        data.forEach((value: List<string>, field: string) => {
            if (value?.join) {
                array.push(`${field} - ${value?.join('-')}`);
            }
        });
        message = array.join('\n');
    }
    return message;
};

export const notification = (params: NotificationParams) => {
    const { type, description, title, open, onClose } = params;
    const icons = {
        success: <CheckCircleIcon style={{ color: 'green' }} />,
        error: <ErrorIcon style={{ color: 'red' }} />,
        info: <InfoIcon style={{ color: 'blue' }} />,
        warning: <WarningIcon style={{ color: 'orange' }} />,
    };

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={onClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <Alert onClose={onClose} severity={type} icon={icons[type]}>
                {title && <AlertTitle>{title}</AlertTitle>}
                {description}
            </Alert>
        </Snackbar>
    );
};