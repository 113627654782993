import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {CompleteProfileForm1Data, CompleteProfileForm2Data, CompleteProfileProps} from './types';
import {ROUTES} from "@routes/constants.ts";
import {useExpert} from "@store/index.ts";

export const useOnMount = (
  setShouldShowGlobalAlert: CompleteProfileProps['setShouldShowGlobalAlert'],
) => {

  return useEffect(() => {
    setShouldShowGlobalAlert(false);
  }, [setShouldShowGlobalAlert]);
};

export const useOnUnmount = (
  setShouldShowGlobalAlert: CompleteProfileProps['setShouldShowGlobalAlert'],
) => {
  return useEffect(() => {
    return () => {
      setShouldShowGlobalAlert(false);
    };
  }, [setShouldShowGlobalAlert]);
};

export const useActivateAndRedirect = (
  setGlobalAlertMessage: CompleteProfileProps['setGlobalAlertMessage'],
  setShouldShowGlobalAlert: CompleteProfileProps['setShouldShowGlobalAlert'],
  completeProfileForm1Data?: CompleteProfileForm1Data,
  completeProfileForm2Data?: CompleteProfileForm2Data,
) => {
  const navigate = useNavigate();

  const email_address = localStorage.getItem('current_user') ?? '';
  const { sendExpertVerificationCode, activateExpert } = useExpert.getState();

  useEffect(() => {
    if (completeProfileForm1Data && completeProfileForm2Data) {
      const {
        company_name,
        company_size,
        company_website,
        country,
        city,
        state,
        zipcode,
        first_name,
        last_name,
        linkedin_profile_url,
        professional_email_address,
      } = completeProfileForm1Data;

      const { industry,
        job_title, skills, years_experience } =
        completeProfileForm2Data;

      setShouldShowGlobalAlert(false);
      activateExpert({
        email_address,
        company_name: company_name ? company_name : undefined,
        company_size: company_size ? company_size : undefined,
        company_website: company_website ? company_website : undefined,
        first_name,
        last_name: last_name ? last_name : undefined,
        linkedin_profile_url: linkedin_profile_url ? linkedin_profile_url : undefined,
        professional_email_address: professional_email_address
          ? professional_email_address
          : undefined,
        industry: industry ? industry : undefined,
        country: country ? country : undefined,
        job_title: job_title ? job_title : undefined,
        skills: skills ? skills : undefined,
        years_experience: years_experience ? years_experience : undefined,
        city: city ? city : undefined,
        state: state ? state : undefined,
        zipcode: zipcode ? zipcode : undefined,
      })
        .then(() => {
          if (professional_email_address) {
            sendExpertVerificationCode(professional_email_address);
            navigate(ROUTES.EXPERT.PATHS.AUTH_VERIFY_PROFESSIONAL_EMAIL);
          } else {
            navigate(ROUTES.EXPERT.PATHS.ACCOUNT_PROFILE);
          }
        })
        .catch(() => {
          setGlobalAlertMessage({
            text: 'An error occurred. Click "Submit" to try again.',
            type: 'error',
          });
          setShouldShowGlobalAlert(true);
        });
    }
  }, [email_address, completeProfileForm1Data, completeProfileForm2Data, setShouldShowGlobalAlert,
    activateExpert, sendExpertVerificationCode, navigate, setGlobalAlertMessage]);
};
