import {PageContainer} from "@components/index";
import styles from './completeProfile.module.scss';
import { useOnMount, useOnUnmount, useActivateAndRedirect } from "./hooks";
import {useEffect, useState} from "react";
import {GlobalAlertMessage} from "@models/GlobalAlert";
import CompleteProfileForm1 from "@pages/Registration/CompleteProfile/CompleteProfileForm1";
import CompleteProfileForm2 from "@pages/Registration/CompleteProfile/CompleteProfileForm2";
import {CompleteProfileForm1Data, CompleteProfileForm2Data} from "@pages/Registration/CompleteProfile/types";
import { FROM } from '@shared/constants';
import {useLocation} from "react-router-dom";
import {useExpert} from "@store/index.ts";
import {patchCurrentExpert} from "@services/experts/experts.service.ts";

const CompleteProfile = () => {
    const from = new URLSearchParams(useLocation().search).get('from');
    const [, setShouldShowGlobalAlert] = useState(false);
    const [, setGlobalAlertMessage] = useState<GlobalAlertMessage | null>(null);
    const [completeProfileForm1Data, setCompleteProfileForm1Data] = useState<CompleteProfileForm1Data>();
    const [completeProfileForm2Data, setCompleteProfileForm2Data] = useState<CompleteProfileForm2Data>();
    const [initValuesForm1, setInitValuesForm1] = useState<CompleteProfileForm1Data>();
    const [initValuesForm2, setInitValuesForm2] = useState<CompleteProfileForm2Data>();
    const [showNextForm, setShowNextForm] = useState(false);
    useOnMount(setShouldShowGlobalAlert);
    useOnUnmount(setShouldShowGlobalAlert);
    useActivateAndRedirect(setGlobalAlertMessage, setShouldShowGlobalAlert, completeProfileForm1Data, completeProfileForm2Data);

    function generateTimestamp(): string {
        const now = new Date();
        const dateStr = now.toISOString().split(".")[0];
        // milliseconds with three digits (JavaScript supports up to millisecond precision)
        const ms = String(now.getMilliseconds()).padStart(3, "0");
        // concatenate the timestamp elements together. Add trailing zeros to emulate microsecond precision.
        return `${dateStr}.${ms}000Z`;
    }

    useEffect(() => {
        const verifyExpert = async () => {
            const expert = useExpert.getState().currentExpert;
            if (!expert.login_email_verified_timestamp) {
                expert.is_login_email_verified = true
                expert.login_email_verified_timestamp = generateTimestamp()
                patchCurrentExpert(expert)
            }
        }
        if (from === FROM.VERIFICATION) {
            verifyExpert()
        }
    }, [from]);
    return <>
        <PageContainer
        >
            <div className={styles.container}>
                {!showNextForm && (
                    <CompleteProfileForm1
                        setShouldShowGlobalAlert={setShouldShowGlobalAlert}
                        setGlobalAlertMessage={setGlobalAlertMessage}
                        setCompleteProfileForm1Data={setCompleteProfileForm1Data}
                        initValuesForm1={initValuesForm1}
                        setInitValuesForm1={setInitValuesForm1}
                        setShowNextForm={setShowNextForm}
                    />
                )}

                {showNextForm && (
                    <CompleteProfileForm2
                        setGlobalAlertMessage={setGlobalAlertMessage}
                        setShouldShowGlobalAlert={setShouldShowGlobalAlert}
                        setCompleteProfileForm2Data={setCompleteProfileForm2Data}
                        setShowNextForm={setShowNextForm}
                        initValuesForm2={initValuesForm2}
                        setInitValuesForm2={setInitValuesForm2}
                    />
                )}
            </div>
        </PageContainer>
    </>
};

export default CompleteProfile;