import { Link } from 'react-router-dom';
import classNames from 'classnames';
import svgSrc from '@assets/images/logo.svg';
import pngSrc from '@assets/images/logo.png';

import useIsMobile from '@hooks/useIsMobile';

import { ROUTES } from '@routes/constants';

import styles from './logo.module.scss';
import {useActivePage} from "@hooks/index.ts";

export type LogoProps = {
    fill?: string;
    stroke?: string;
    isAuthenticated?: boolean;
};

const Logo = (props: LogoProps) => {
    const { fill, stroke, isAuthenticated } = props;
    const isMobile = useIsMobile();
    const [activePage] = useActivePage();
    const blackLogoPages = [
        'AUTH_LOGIN',
        'AUTH_SIGN_UP',
        'AUTH_CHECK_EMAIL',
        'AUTH_FORGOT_PASSWORD',
        'AUTH_EMAIL_ACTION'
    ];

    const showBlackLogo = () => {
        if (blackLogoPages.includes(activePage)) {
            return false;
        }
        return !(activePage === 'SUPPORT' && !isAuthenticated);
    };
    return (
        <div
            className={classNames({
                [styles.logo]: true,
                [styles['is-mobile']]: isMobile,
            })}
        >
            <Link className={styles['logo-link']} to={ROUTES.EXPERT.PATHS.ROOT}>
                {showBlackLogo() && (
                    <img
                        className={styles.logoSvg}
                        src={svgSrc}
                        alt="Company Logo"
                        style={{ fill, stroke }}
                    />
                )}
                {!showBlackLogo() && (
                    <img className={styles.logoPng} src={pngSrc} alt="Company Logo" aria-labelledby="Company Logo" />
                )}
            </Link>
        </div>
    );
};

export default Logo;
