import {FC, useEffect, useState} from 'react';
import { Box, List, ListItem, Typography, ListItemIcon, IconButton, InputAdornment, TextField } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { Record, RecordOf } from 'immutable';
import { NewPasswordInputProps, PasswordValidationCheck } from './types';
import { useHandleChange } from './hooks';
import {SxProps, Theme} from "@mui/material/styles";

export const NewPasswordInput: FC<NewPasswordInputProps> = (props) => {
    const { className, onChange,
        placeholder, label, onValidationChange,  showValidation } = props;

    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [passwordValidationCheck, setPasswordValidationCheck] = useState<RecordOf<PasswordValidationCheck>>(
        Record<PasswordValidationCheck>({
            eightCharacterChecked: 'DEFAULT',
            lowerCaseCharacterChecked: 'DEFAULT',
            upperCaseCharacterChecked: 'DEFAULT',
            numberOrSpecialCharacterChecked: 'DEFAULT',
        })(),
    );

    useEffect(() => {
        const {
            eightCharacterChecked,
            lowerCaseCharacterChecked,
            upperCaseCharacterChecked,
            numberOrSpecialCharacterChecked,
        } = passwordValidationCheck.toJSON();

        const isValid = [eightCharacterChecked, lowerCaseCharacterChecked, upperCaseCharacterChecked, numberOrSpecialCharacterChecked].every(
            (check) => check === 'VALID'
        );

        if (onValidationChange) {
            onValidationChange(isValid);
        }
    }, [passwordValidationCheck, onValidationChange]);

    const handleChange = useHandleChange(onChange, setPasswordValidationCheck);

    const {
        eightCharacterChecked,
        lowerCaseCharacterChecked,
        numberOrSpecialCharacterChecked,
        upperCaseCharacterChecked,
    } = passwordValidationCheck.toJSON();

    const validationMessages = [
        { checked: eightCharacterChecked, text: 'Must be at least 8 characters' },
        { checked: lowerCaseCharacterChecked, text: 'Must have one lowercase character' },
        { checked: upperCaseCharacterChecked, text: 'Must have one uppercase character' },
        { checked: numberOrSpecialCharacterChecked, text: 'Must have either a number or special character' },
    ];

    const getValidationClass = (checked: string) => {
        switch (checked) {
            case 'VALID':
                return {
                    color: 'green',
                    marginLeft: '-16px',
                    minWidth: '30px',
                };
            case 'INVALID':
                return {
                    color: 'red',
                    marginLeft: '-16px',
                    minWidth: '30px',
                };
            default:
                return {
                    color: 'text.secondary',
                    marginLeft: '-16px',
                    minWidth: '30px',
                };
        }
    };

    const getValidationIcon = (checked: string) => {
        switch (checked) {
            case 'VALID':
                return <CheckIcon sx={{ fill: 'green', fontSize: '9px' }} />;
            case 'INVALID':
                return <CloseIcon sx={{ fill: 'red', fontSize: '9px' }} />;
            default:
                return <FiberManualRecordIcon sx={{ fill: 'dimgray', fontSize: '5px' }} />;
        }
    };

    const togglePasswordVisibility = () => {
        setPasswordVisibility(!passwordVisibility);
    };

    const sxInput: SxProps<Theme> = {
        fontSize: '20px',
        color: 'black',
        "& fieldset": {
            borderRadius: '8px',
        },
        '&.Mui-focused': {
            borderColor: 'primary.main',
            boxShadow: 'none',
        },
        '&.Mui-error': {
            border: '2px solid',
            borderColor: 'error.main',
        },
    };

    return (
        <Box className={className} sx={{ width: '100%' }}>
            <TextField
                type={passwordVisibility ? 'text' : 'password'}
                label={label}
                placeholder={placeholder}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={sxInput}
                helperText={showValidation && (
                    <Box sx={{ position: 'relative'}}>
                        <List sx={{ paddingLeft: '24px', paddingTop: 0 }}>
                            {validationMessages.map((message, index) => (
                                <ListItem key={index} sx={{ ...getValidationClass(message.checked), paddingTop: 0, paddingBottom: 0, paddingRight: 0}}>
                                    <ListItemIcon sx={getValidationClass(message.checked)}>
                                        {getValidationIcon(message.checked)}
                                    </ListItemIcon>
                                    <Typography variant="caption" sx={getValidationClass(message.checked)}>
                                        {message.text}
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                )}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={togglePasswordVisibility} edge="end">
                                {passwordVisibility ? <VisibilityOff sx={{fill: 'rgba(0,0,0,0.5)', marginRight : '4px'}} /> :
                                    <Visibility sx={{fill: 'rgba(0,0,0,0.5)', marginRight : '4px'}} />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    );
};

export default NewPasswordInput;