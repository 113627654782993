import { Button, Grid, Typography } from '@mui/material';
import notFound from '@assets/images/notFound.png';
import { useGoBack } from './hooks';
import styles from './notFound.module.scss';

const NotFound = () => {
    const handleBack = useGoBack();

    return (
        <Grid container className={styles.container}>
            <Grid item>
                <img className={styles.image} src={notFound} alt="Not found - 404 page" />
            </Grid>
            <Grid item className={styles.rightCol}>
                <Typography variant="h1">404</Typography>
                <Typography variant="h5">Sorry, something went wrong</Typography>
                <Button variant="contained" color="primary" onClick={handleBack}>
                    GO BACK
                </Button>
            </Grid>
        </Grid>
    );
};

export default NotFound;