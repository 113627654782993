import axios, { Canceler } from 'axios';

import { NXAxiosRequestConfig } from './types';

const CANCELLED_REQUEST_ERROR_MESSAGE = 'Cancelled pending request';

export const pendingRequests = new Map<string | undefined, Canceler>();

export const addPendingRequest = (config: NXAxiosRequestConfig) => {
    if (!config.cancelPendingRequests) {
        return;
    }

    const stringifiedUrl = getStringifiedPendingUrl(config);

    config.cancelToken =
        config.cancelToken ||
        new axios.CancelToken((cancel) => {
            if (!pendingRequests.has(stringifiedUrl)) {
                pendingRequests.set(stringifiedUrl, cancel);
            }
        });
};

export const removePendingRequest = (config: NXAxiosRequestConfig) => {
    const stringifiedUrl = getStringifiedPendingUrl(config);

    if (pendingRequests.has(stringifiedUrl)) {
        pendingRequests.delete(stringifiedUrl);
    }
};

export const cancelPendingRequest = (config: NXAxiosRequestConfig) => {
    const stringifiedUrl = getStringifiedPendingUrl(config);

    if (pendingRequests.has(stringifiedUrl)) {
        const cancel = pendingRequests.get(stringifiedUrl);

        if (cancel) {
            cancel(CANCELLED_REQUEST_ERROR_MESSAGE);
        }
    }
};

export const getStringifiedPendingUrl = (config: NXAxiosRequestConfig) => {
    if (!config?.cancelPendingRequests) {
        return undefined;
    }

    return config.cancelPendingRequests.samePath
        ? [
            config.method,
            config.url,
            config.cancelPendingRequests.sameQuery ? JSON.stringify(config.params) : undefined,
        ].join('&')
        : '';
};
