import {useEffect} from "react";
import AppRoutes from "@routes/routes";
import { Layout } from "@components/index";
import styles from "./app.module.scss";
import { useAuthentication } from "@store/index.ts";
import {useActivePage} from "@hooks/index.ts";

const App = () => {
    // Use the Zustand hook to subscribe to the authentication state
    const isAuthenticated = useAuthentication((state) => state.isAuthenticated);
    const [activePage,] = useActivePage();
    const getTokens = async () => {
        await useAuthentication.getState().getAccessToken();
    };

    useEffect(() => {
        const startApp = async () => {
            await getTokens();
        };
        startApp();
    }, []);

    return (
        <>
            <Layout className={styles.container} isAuthenticated={isAuthenticated}
                    canHaveLoginButton={activePage === 'ACCOUNT_SECURITY' || activePage === 'ACCOUNT_PROFILE' || activePage === 'SUPPORT'}>
                <div className={styles.backgroundContainer}>
                    <div className={styles.ellipse1}></div>
                    <div className={styles.ellipse2}></div>
                    <div className={styles.ellipse3}></div>
                </div>
                <AppRoutes/>
            </Layout>
        </>
    );
};

export default App;