import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface PromptProps {
    when: boolean;
    message: string;
}

const usePrompt = (message: string, when: boolean) => {
    const navigate = useNavigate();
    const [lastLocation,] = useState<string | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (when) {
                event.preventDefault();
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [when, message]);

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            navigate(lastLocation, { replace: true });
            setConfirmedNavigation(false);
        }
    }, [confirmedNavigation, lastLocation, navigate]);

    return null;
};

const Prompt: React.FC<PromptProps> = ({ when, message }) => {
    usePrompt(message, when);
    return null;
};

export default Prompt;