import { PasswordValidationState } from './types';

export const PASSWORD_VALIDATION_MESSAGE_CLASS_NAMES: {
  [key in PasswordValidationState]: string;
} = {
  DEFAULT: 'default',
  INVALID: 'invalid',
  VALID: 'valid',
};

export const UPPER_CASE_REGEX = /[A-Z]/;

export const LOWER_CASE_REGEX = /[a-z]/;

export const NUMBER_CHAR_REGEX = /[0-9]/;

export const SPECIAL_CHARS_REGEX = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
