import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
    Box, Button as MuiButton, Checkbox as MuiCheckbox, FormControlLabel,
    TextField, Typography, Dialog, DialogTitle, DialogContent,
    InputAdornment, Tooltip, IconButton,
} from '@mui/material';
import {useForm, Controller, useWatch, Control, UseFormSetValue} from 'react-hook-form';

import {CompanyDetailsForm, PersonalDetailsForm, TermsAndConditions} from '@components/index';
import { professionalEmailValidator } from '@utilities/professionalEmailValidator';

import { useHandleSubmit, useOnUnmount } from './hooks';
import { useIsMobile } from '@hooks/index';

import { CompleteProfileForm1Props } from './types';

import styles from './completeProfileForm1.module.scss';
import {CompleteProfileForm1Data} from "@pages/Registration/CompleteProfile/types.ts";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {EditSettingsFormData} from "@pages/Profile/types.ts";
import {useLocationOptions} from "@pages/Profile/hooks.tsx";

export const CompleteProfileForm1: React.FC<CompleteProfileForm1Props> = (props) => {
    const {
        setShowNextForm, setInitValuesForm1,
        setShouldShowGlobalAlert, setGlobalAlertMessage,
        setCompleteProfileForm1Data, initValuesForm1
    } = props;

    const isMobile = useIsMobile();
    const [isLinkedlnHelpVisible, setIsLinkedlnHelpVisible] = useState<boolean>(false);
    const [selectedCountry, setSelectedCountry] = useState<string>('');
    const { control, handleSubmit, formState: { errors }, setValue } = useForm<CompleteProfileForm1Data>({
        defaultValues: initValuesForm1,
    });
    const handleFormSubmit = useHandleSubmit(setShouldShowGlobalAlert, setGlobalAlertMessage, setCompleteProfileForm1Data, setShowNextForm);

    useOnUnmount(setShouldShowGlobalAlert);

    const values = useWatch({ control });

    useEffect(() => {
        setInitValuesForm1(values as CompleteProfileForm1Data);
        setSelectedCountry(values.country ?? '');
    }, [setInitValuesForm1, values]);

    const locationOptions = useLocationOptions();

    return (
        <Box className={classNames(styles.registration, { [styles['is-mobile']]: isMobile })}>
            <Dialog
                open={isLinkedlnHelpVisible}
                onClose={() => setIsLinkedlnHelpVisible(false)}
                fullWidth
                maxWidth={isMobile ? 'xs' : 'md'}
                PaperProps={isMobile ? { sx: { top: '8px' } } : {}}
            >
                <DialogTitle>
                    <Typography variant="h5" fontWeight="bold">
                        Terms and conditions
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <TermsAndConditions />
                </DialogContent>
            </Dialog>

            <Typography variant='h1' fontWeight="bold" textAlign="left" mb={4} mt={2}>
                Complete your profile
            </Typography>

            <form onSubmit={handleSubmit(handleFormSubmit)} className={styles.form} noValidate>
                <PersonalDetailsForm
                    control={control as Control<EditSettingsFormData>}
                    setSelectedCountry={setSelectedCountry}
                    setValue={setValue as UseFormSetValue<EditSettingsFormData>}
                    selectedCountry={selectedCountry}
                    locationOptions={locationOptions}
                />

                {selectedCountry === 'United States' && <div style={{marginBottom: '16px'}}></div>}

                <CompanyDetailsForm control={control as Control<EditSettingsFormData>} />

                <Typography variant='body1' className={styles.message}>
                    To have your profile verified as quickly as possible, provide at least one of the items below. If you do not have a professional email or LinkedIn account, NewtonX will reach out to you via email to verify your profile.
                </Typography>

                <Controller
                    name="professional_email_address"
                    control={control}
                    rules={{
                        validate: (value) => {
                            if (!values.hasNoLinkedinURLOrEmail && !values.linkedin_profile_url && !value) {
                                return 'Professional email is required';
                            }
                            if (value && !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(value)) {
                                return 'Please input a valid email';
                            }
                            return professionalEmailValidator(value ?? '');
                        }
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Professional email"
                            variant="outlined"
                            fullWidth
                            disabled={values.hasNoLinkedinURLOrEmail}
                            error={!!errors.professional_email_address}
                            helperText={errors.professional_email_address ? errors.professional_email_address.message : ''}
                            sx={{ mb: 2 }}
                        />
                    )}
                />
                <Controller
                    name="linkedin_profile_url"
                    control={control}
                    rules={{
                        pattern: {
                            value: /^(https?:\/\/)?(www\.)?([\w-]+\.)+[\w-]{2,4}(\/[\w-]*)*$/,
                            message: 'Enter a valid URL',
                        },
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="LinkedIn URL"
                            variant="outlined"
                            fullWidth
                            disabled={values.hasNoLinkedinURLOrEmail}
                            error={!!errors.linkedin_profile_url}
                            helperText={errors.linkedin_profile_url ? errors.linkedin_profile_url.message : ''}
                            sx={{ mb: 2 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Tooltip title="To find your LinkedIn URL, sign in to LinkedIn and click “Me.” Then select “View My Profile” and copy the link that appears in your browser’s address bar." placement="left">
                                            <IconButton sx={{ padding: 0 }}>
                                                <InfoOutlinedIcon sx={{ fill: 'rgba(0,0,0,0.5)' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />

                <Controller
                    name="hasNoLinkedinURLOrEmail"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<MuiCheckbox
                                checked={initValuesForm1?.hasNoLinkedinURLOrEmail}
                                {...field}
                                onChange={(e) => {
                                    field.onChange(e.target.checked);
                                    if (e.target.checked) {
                                        setValue('linkedin_profile_url', '');
                                        setValue('professional_email_address', '');
                                    }
                                }}
                            />}
                            label="I do not have a professional email or LinkedIn account."
                            sx={{ mb: 2 }}
                        />
                    )}
                />

                <MuiButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mb: 2 }}
                >
                    NEXT
                </MuiButton>
            </form>
        </Box>
    );
};

export default CompleteProfileForm1;