import { TextField, Autocomplete, Typography } from '@mui/material';
import {Control, Controller} from 'react-hook-form';
import { COMPANY_SIZE_OPTIONS } from '@shared/constants.ts';
import styles from '@pages/Profile/profile.module.scss';
import {EditSettingsFormData} from "@pages/Profile/types.ts";

interface JobDetailsFormProps {
    control: Control<EditSettingsFormData>;
    showTitles? : boolean;
}

const CompanyDetailsForm: React.FC<JobDetailsFormProps> = ({ control, showTitles }) => (
    <>
        {showTitles && <Typography variant='h4' className={styles.subHeader}>
            Current company
        </Typography>}

        <Controller
            name="company_name"
            control={control}
            rules={{ required: 'Please input your company name' }}
            render={({ field, fieldState }) => (
                <TextField
                    {...field}
                    label="Company name*"
                    variant="outlined"
                    sx={{ marginBottom: '16px' }}
                    fullWidth
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                />
            )}
        />

        <Controller
            name="company_size"
            control={control}
            rules={{ required: 'Please input your company size' }}
            render={({ field, fieldState }) => (
                <Autocomplete
                    {...field}
                    options={COMPANY_SIZE_OPTIONS.map(option => (option.value))}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    onChange={(_, value) => field.onChange(value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Company size*"
                            variant="outlined"
                            sx={{ marginBottom: '16px' }}
                            fullWidth
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                        />
                    )}
                />
            )}
        />

        <Controller
            name="company_website"
            control={control}
            rules={{
                pattern: {
                    value: /^(https?:\/\/)?(www\.)?([\w-]+\.)+[\w-]{2,4}(\/[\w-]*)*$/,
                    message: 'Enter a valid website URL',
                },
            }}
            render={({ field, fieldState }) => (
                <TextField
                    {...field}
                    label="Company website"
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                />
            )}
        />
    </>
);

export default CompanyDetailsForm;