import { useCallback, useEffect } from 'react';

import { ROUTES } from '@routes/constants';

import { VerifyProfileFormProps } from './types';

import { useExpert } from "@store/index.ts";
import CustomAlert from "@components/atoms/Alert";

export const useOnSubmit = (verification_code : string) => {
  const { verifyExpert } = useExpert.getState();
  const email_address = useEmailAddress();
  return useCallback(async () => {
    if (email_address && verification_code.length === 6){
      await verifyExpert({
        email_address,
        verification_code,
      });
      window.location.replace(`${ROUTES.EXPERT.PATHS.AUTH_LOGIN}?from=verification`);
    }
  }, [email_address, verification_code, verifyExpert]);
};

export const useOnMount = () => {
  useEffect(() => {
  }, []);
};

export const useOnUnmount = (
  setShouldShowGlobalAlert: VerifyProfileFormProps['setShouldShowGlobalAlert'],
) => {
  useEffect(() => {
    return () => {
      setShouldShowGlobalAlert(false);
    };
  }, [setShouldShowGlobalAlert]);
};

export const useOnClickGetCodeAgain = (
  setShouldShowGlobalAlert: VerifyProfileFormProps['setShouldShowGlobalAlert'],
  setGlobalAlertMessage: VerifyProfileFormProps['setGlobalAlertMessage'],
) => {
  const { sendExpertVerificationCode } = useExpert.getState();

  const email_address = useEmailAddress();

  return useCallback(async () => {
    setShouldShowGlobalAlert(false);

    if(email_address) {
      await sendExpertVerificationCode(email_address);

      setGlobalAlertMessage({
        text: <CustomAlert title="New verification code sent" message='A new verification code has been sent to the professional email address you provided.' />,
        type: 'info',
      });
    } else {
      setGlobalAlertMessage({
        text: <CustomAlert title="Error" message='Failed to verify verification code.' type="error" />,
        type: 'error',
      });
    }

    setShouldShowGlobalAlert(true);
  }, [setShouldShowGlobalAlert, email_address, sendExpertVerificationCode, setGlobalAlertMessage]);
};

export const useEmailAddress = () => {
  return localStorage.getItem('current_professional_email');
};
