import { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ResetPasswordFirstPageProps, ResetPasswordFirstPageFormData } from './types';

import { useAuthentication } from '@store/index';
import styles from './resetPasswordFirstPage.module.scss';
import { ROUTES } from '@routes/constants';
import { Grid, Typography } from '@mui/material';

export const useSubmit = (
    setGlobalAlertMessage: ResetPasswordFirstPageProps['setGlobalAlertMessage'],
    setShouldShowGlobalAlert: ResetPasswordFirstPageProps['setShouldShowGlobalAlert'],
) => {
    return useCallback(
        ({ email }: ResetPasswordFirstPageFormData) => {
            return useAuthentication
                .getState()
                .changePasswordEmail(email)
                .then(() => {
                    localStorage.setItem('current_user', email);
                    setGlobalAlertMessage({
                        text: (
                            <div className={styles.alertWrapper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Typography variant='subtitle1' sx={{fontWeight: 'bold'}}>Check your email</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='body2' className={styles.successAlertBody}>
                                            If an account with that email address exists, you’ll receive a password reset link within 10 minutes. If you still need help,{' '}
                                            <Link
                                                to={ROUTES.EXPERT.PATHS.SUPPORT}
                                                target="_blank"
                                                className={styles.supportPageLink}
                                            >
                                                reach out to us via the Support page
                                            </Link>
                                            .
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        ),
                        type: 'info',
                        closable: true,
                        showIcon: false,
                    });
                })
                .catch(() => {
                    setGlobalAlertMessage({
                        type: 'error',
                        text: 'Your request could not be submitted. Click "Reset Password" to try again.',
                        closable: true,
                    });
                })
                .finally(() => {
                    setShouldShowGlobalAlert(true);
                });
        },
        [setGlobalAlertMessage, setShouldShowGlobalAlert],
    );
};

export const useUnmount = (
    setShouldShowGlobalAlert: ResetPasswordFirstPageProps['setShouldShowGlobalAlert'],
) => {
    useEffect(() => {
        return () => {
            setShouldShowGlobalAlert(false);
        };
    }, [setShouldShowGlobalAlert]);
};