import {Autocomplete, TextField, Typography} from '@mui/material';
import {Control, Controller} from 'react-hook-form';
import styles from '@pages/Profile/profile.module.scss';
import {EditSettingsFormData} from "@pages/Profile/types.ts";

interface JobDetailsFormProps {
    control: Control<EditSettingsFormData>;
    industryOptions?: string[];
    showTitles? : boolean;
}

const JobDetailsForm: React.FC<JobDetailsFormProps> = ({ control, showTitles, industryOptions }) => (
    <>
        {showTitles && <Typography variant='h4' className={styles.subHeader}>
            Job details
        </Typography>}

        <Controller
            name="job_title"
            control={control}
            rules={{ required: 'Please input your job title' }}
            render={({ field, fieldState }) => (
                <TextField
                    {...field}
                    label="Job title*"
                    sx={{ marginBottom: '16px' }}
                    variant="outlined"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                />
            )}
        />

        <Controller
            name="years_experience"
            control={control}
            rules={{
                required: 'Please provide your work experience',
                min: {
                    value: 1,
                    message: 'Total years of work experience must be at least 1',
                },
            }}
            render={({ field, fieldState }) => (
                <TextField
                    {...field}
                    label="Total years of work experience*"
                    variant="outlined"
                    sx={{ marginBottom: '16px' }}
                    fullWidth
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onKeyDown={(e) => {
                        if (['e', 'E', '+', '-'].includes(e.key)) {
                            e.preventDefault();
                        }
                    }}
                />
            )}
        />

        {industryOptions && <Controller
            name="industry"
            control={control}
            rules={{ required: 'Current industry is required' }}
            render={({ field, fieldState }) => (
                <Autocomplete
                    {...field}
                    options={industryOptions}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    onChange={(_, value) => field.onChange(value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Current industry*"
                            variant="outlined"
                            sx={{ mb: 2 }}
                            fullWidth
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                        />
                    )}
                />
            )}
        />}

        <Typography variant='body2' className={styles.message}>
            In 3 or 4 sentences, describe your job responsibilities and relevant professional experience.
        </Typography>

        <Controller
            name="skills"
            control={control}
            rules={{ required: 'Please provide your responsibilities' }}
            render={({ field, fieldState }) => (
                <TextField
                    {...field}
                    label="Short professional bio*"
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: '8px' }}
                    multiline
                    rows={4}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                />
            )}
        />
    </>
);

export default JobDetailsForm;