type AnyObject = { [key: string]: string };

const invertObject = (obj: AnyObject): AnyObject => {
    if (typeof obj !== 'object' || obj == null) {
        return {};
    }

    const retObj: AnyObject = {};
    Object.keys(obj).forEach((key) => {
        retObj[obj[key]] = key;
    });

    return retObj;
};

export default invertObject;