import * as React from 'react';
import TextField from '@mui/material/TextField';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { SxProps, Theme } from '@mui/material/styles';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
      const { onChange, ...other } = props;

      return (
          <NumericFormat
              {...other}
              getInputRef={ref}
              onValueChange={(values) => {
                onChange({
                  target: {
                    name: props.name,
                    value: values.value,
                  },
                });
              }}
              thousandSeparator
              valueIsNumericString
              prefix="$"
          />
      );
    },
);

interface InputNumberProps extends CustomProps {
  sx?: SxProps<Theme>;
}

const CustomInputNumber: React.FC<InputNumberProps> = (props) => {
  const { name, onChange, sx, ...otherProps } = props;

  return (
      <TextField
          {...otherProps}
          name={name}
          onChange={onChange}
          InputProps={{
            inputComponent: NumericFormatCustom as never,
          }}
          sx={{
            '& .MuiInputBase-root': {
              height: '48px',
              borderRadius: '8px',
              fontSize: '20px',
              color: 'black',
              '& input': {
                '-moz-appearance': 'textfield', // Remove up/down arrows in Firefox
              },
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none', // Remove up/down arrows in Chrome
                margin: 0,
              },
            },
            ...sx,
          }}
      />
  );
};

export default CustomInputNumber;