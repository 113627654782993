import { FC } from 'react';
import TextField from '@mui/material/TextField';
import { SxProps, Theme } from '@mui/material/styles';

import { Props } from './types';

const Input: FC<Props> = (props) => {
    const { customType = 'text', ...otherProps } = props;

    const sx: SxProps<Theme> = {
        fontSize: '20px',
        color: 'black',
        "& fieldset": {
            borderRadius: '8px',
        },
        '& .MuiInputBase-root': {
            padding: '0 20px',
        },
        '&.Mui-focused': {
            borderColor: 'primary.main',
            boxShadow: 'none',
        },
        '&.Mui-error': {
            border: '2px solid',
            borderColor: 'error.main',
        },
    };

    return (
        <TextField
            type={customType}
            sx={sx}
            aria-label={customType === 'password' ? 'password' : undefined}
            {...otherProps}
            InputProps={{
                sx: {
                    height: '100%',
                    borderRadius: '8px',
                }
            }}
        />
    );
};

export default Input;