import { memo, useState, useEffect, useRef } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { PageContainer } from '@components/index';
import {
    useOnClickGetCodeAgain,
    useOnUnmount,
    useOnSubmit,
} from './hooks.tsx';
import { GlobalAlertMessage } from '@models/GlobalAlert.ts';
import { VerifyProfileFormData } from './types';
import { Typography, TextField, Box } from '@mui/material';
import styles from './verifyProfile.module.scss';

type numBoxes = 0 | 1 | 2 | 3 | 4 | 5;

export const VerifyProfessionalEmail = memo(() => {
    const [shouldShowGlobalAlert, setShouldShowGlobalAlert] = useState(false);
    const [globalAlertMessage, setGlobalAlertMessage] = useState<GlobalAlertMessage | null>(null);
    const { control, setValue, handleSubmit } = useForm<VerifyProfileFormData>();
    const [verificationCode, setVerificationCode] = useState<string>('');
    const onClickGetCodeAgain = useOnClickGetCodeAgain(
        setShouldShowGlobalAlert,
        setGlobalAlertMessage,
    );

    const onSubmit = useOnSubmit(verificationCode);
    useOnUnmount(setShouldShowGlobalAlert);

    const values = useWatch({ control });

    useEffect(() => {
        const { verification_code0, verification_code1, verification_code2, verification_code3, verification_code4, verification_code5 } = values;
        if (!!verification_code0 && !!verification_code1 && !!verification_code2 &&
            !!verification_code3 && !!verification_code4 && !!verification_code5) {
            setVerificationCode(`${verification_code0}${verification_code1}${verification_code2}${verification_code3}${verification_code4}${verification_code5}`);
        }
    }, [values]);

    useEffect(() => {
        if (verificationCode.length === 6) {
            handleSubmit(onSubmit)();
        }
    }, [verificationCode, handleSubmit, onSubmit]);

    const inputsRef = useRef<Array<HTMLInputElement | null>>([]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: numBoxes) => {
        const value = e.target.value;
        setValue(`verification_code${index}`, value, { shouldValidate: true, shouldDirty: true });
        if (/^\d$/.test(value)) {
            if (index < 5 && value) {
                inputsRef.current[index + 1]?.focus();
            }
        } else if (!value && index > 0) {
            inputsRef.current[index - 1]?.focus();
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, index: numBoxes) => {
        if (e.key === 'Backspace' && !inputsRef.current[index]?.value) {
            if (index > 0) {
                setValue(`verification_code${index}`, '', { shouldValidate: true, shouldDirty: true });
                inputsRef.current[index - 1]?.focus();
            }
        }
    };

    return (
        <PageContainer
            setShouldShowGlobalAlert={setShouldShowGlobalAlert}
            globalAlertMessage={globalAlertMessage}
            shouldShowGlobalAlert={shouldShowGlobalAlert}
        >
            <div className={styles.mainContainer}>
                <Typography variant='h1'>
                    Enter your verification code
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)} className={styles.form} noValidate>
                    <Typography variant='body1' className={styles.message}>
                        We just sent a verification code to the professional email you provided. Enter that code
                        below to verify your profile. The code will expire in 10 minutes. If you need a new
                        code,{' '}
                        <span className={styles.getCodeAgain} onClick={onClickGetCodeAgain}>
                            click here
                        </span>
                        .
                    </Typography>

                    <Box display="flex" justifyContent="space-between" margin="auto">
                        {[...Array(6)].map((_, index) => (
                            <Controller
                                key={index}
                                name={`verification_code${index as numBoxes}`}
                                control={control}
                                defaultValue=""
                                rules={{ required: 'Please provide the verification code' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        inputRef={(el) => (inputsRef.current[index] = el)}
                                        onChange={(e) => handleChange(e, index as numBoxes)}
                                        onKeyDown={(e) => handleKeyDown(e, index as numBoxes)}
                                        variant="outlined"
                                        inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                                        sx={{ width: '56px', margin: '0 4px' }}
                                    />
                                )}
                            />
                        ))}
                    </Box>
                </form>
            </div>
        </PageContainer>
    );
});

export default VerifyProfessionalEmail;